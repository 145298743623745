import { useEffect, useMemo, useState } from 'react';
import { useWindowSize } from 'react-use';

import { ButtonProps } from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

import CustomDialogWrapper from '../../../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import CustomTextField from '../../../../../../../../common/components/CustomTextField/CustomTextField';
import GenericObject from '../../../../../../../../typesAdditional/GenericObject';
import CardSessionsSelector from '../CardsSessionsSelector/CardSessionsSelector';
import TableSessionsSelector from '../TableSessionsSelector/TableSessionsSelector';

interface Props {
    courseId: string;
    open: boolean;
    onClose: () => void;
    suggestions: GenericObject[];
    sessionType: string;
    isSelected: (sessionId: string) => boolean;
    handleSelect: (sessionId: string, value: boolean) => void;
    handleFoodSelect: (sessionId: string, value: boolean) => void;
    resetSessions: () => void;
    nextStep: () => void;
    questionResponse: string;
    schoolGrade: number;
    tmpFixCorsiAdulti: boolean;
    getSessionPriceWithOffset: (sessionId: string) => number;
    selectedSessions: any[];
}

const SuggestionsModal = (props: Props) => {
    const { courseId, open, onClose, suggestions, sessionType, isSelected, handleSelect, handleFoodSelect, resetSessions, nextStep, questionResponse, schoolGrade, tmpFixCorsiAdulti, getSessionPriceWithOffset, selectedSessions } = props;

    const [town, setTown] = useState<string>('');

    const { width } = useWindowSize();

    useEffect(() => {
        if (!open) return;

        setTown('');
        resetSessions();
    }, [resetSessions, open]);

    const buttons: ButtonProps[] = [
        {
            children: 'Annulla',
            color: 'primary',
            onClick: () => {
                resetSessions();
                onClose();
            }
        },
        {
            children: 'Continua',
            color: 'primary',
            onClick: () => {
                onClose();
                nextStep();
            }
        }
    ];

    const isLoading = false;

    const locations = useMemo(() => {
        return suggestions
            .filter((s: any) => (!s.question || s.question === questionResponse) && questionResponse !== s.disabledQuestion)
            .reduce((prev: GenericObject[], curr) => {
                if (prev.find((l: any) => l.id === curr.location.town.id)) {
                    return prev;
                } else {
                    return [...prev, { id: curr.location.town.id, name: curr.location.townName }];
                }
            }, []);
    }, [suggestions, questionResponse]);

    const doFilter = suggestions.length > 5;

    const simulateRegistrationData = {
        sessions: doFilter ? suggestions.filter(s => s.location.town.id === town) : suggestions
    };

    const isFoodSelected = (_: string) => false;



    return (
        <CustomDialogWrapper open={open} onClose={onClose} title={'Aggiunta iscrizione'} buttons={buttons} isLoading={isLoading} maxWidth='md' fullWidth>
            {doFilter && (
                <CustomTextField
                    select
                    label='Paese in cui desideri iscriverti'
                    value={town}
                    onChange={(e: any) => {
                        setTown(e.target.value);
                    }}
                    variant='outlined'
                    fullWidth
                >
                    {locations.sort((location1: any, location2: any) => {
                        return location1.name.localeCompare(location2.name);
                    }).map((location: any) => (
                        <MenuItem key={location.id} value={location.id}>
                            {location.name}
                        </MenuItem>
                    ))}
                </CustomTextField>
            )}

            {(town || !doFilter) ? (
                <>
                    <Typography variant='h4' style={{ fontSize: '1.4em', textAlign: 'center', margin: '8px', marginBottom: '10px' }}>
                        Seleziona {(sessionType === 'single_event' || sessionType === 'weekly_events') ? 'i giorni aggiuntivi a cui' : 'le settimane aggiuntive a cui'} desideri iscriverti
                    </Typography>

                    <div style={{ marginBottom: '32px' }}>
                        {width > 700 ? (
                            <TableSessionsSelector
                                courseId={courseId}
                                sessionType={sessionType}
                                hasFoodService={false}
                                simulateRegistrationData={simulateRegistrationData}
                                isSelected={isSelected}
                                isFoodSelected={isFoodSelected}
                                handleSelect={handleSelect}
                                handleFoodSelect={handleFoodSelect}
                                questionResponse={questionResponse}
                                showTownName={!doFilter}
                                schoolGrade={schoolGrade}
                                tmpFixCorsiAdulti={tmpFixCorsiAdulti}
                                getSessionPriceWithOffset={getSessionPriceWithOffset}
                                selectedSessions={selectedSessions}
                            />
                        ) : (
                            <CardSessionsSelector
                                courseId={courseId}
                                sessionType={sessionType}
                                hasFoodService={false}
                                simulateRegistrationData={simulateRegistrationData}
                                isSelected={isSelected}
                                isFoodSelected={isFoodSelected}
                                handleSelect={handleSelect}
                                handleFoodSelect={handleFoodSelect}
                                questionResponse={questionResponse}
                                showTownName={!doFilter}
                                schoolGrade={schoolGrade}
                                tmpFixCorsiAdulti={tmpFixCorsiAdulti}
                                getSessionPriceWithOffset={getSessionPriceWithOffset}
                                selectedSessions={selectedSessions}
                            />
                        )}
                    </div>
                </>
            ) : (
                <>
                    <Typography variant='h4' style={{ fontSize: '1.4em', textAlign: 'center', margin: '8px', marginBottom: '10px' }}>
                        Seleziona il paese dove desideri iscriverti
                    </Typography>
                </>
            )}

        </CustomDialogWrapper>
    );
};

export default SuggestionsModal;
