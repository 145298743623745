import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useWindowSize } from 'react-use';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import useGenericErrorDialog from '../../../../../../common/hooks/useGenericErrorDialog/useGenericErrorDialog';
import { DialogOption, useMessageDialog } from '../../../../../../common/hooks/useMessageDialog/useMessageDialog';
import api from '../../../../../../common/utils/api';
import GenericObject from '../../../../../../typesAdditional/GenericObject';
import { FormSection } from '../../../../../public/pages/registrationPage/components/GenericPersonForm/GenericPersonForm';
import LoadingSimulationAlert from '../../../../../public/pages/registrationPage/components/LoadingSimulationAlert/LoadingSimulationAlert';
import SimulationRecapAlert from '../../../../../public/pages/registrationPage/components/SimulationRecapAlert/SimulationRecapAlert';
import useSimulateRegistration from '../../../../../public/pages/registrationPage/hooks/useSimulateRegistration/useSimulateRegistration';
import useValidatePersonStep, { Props as ValidatePersonStepProps } from '../../../../../public/pages/registrationPage/hooks/useValidatePersonStep/useValidatePersonStep';
import CardSessionsSelector from '../../../../../public/pages/registrationPage/steps/RegistrationStep6/components/CardsSessionsSelector/CardSessionsSelector';
import DiscountSelector from '../../../../../public/pages/registrationPage/steps/RegistrationStep6/components/DiscountSelector/DiscountSelector';
import TableSessionsSelector from '../../../../../public/pages/registrationPage/steps/RegistrationStep6/components/TableSessionsSelector/TableSessionsSelector';
import useHandleSelect from '../../../../../public/pages/registrationPage/steps/RegistrationStep6/hooks/useHandleSelect';
import PersonalData from '../../sections/PersonalData/PersonalData';

interface Props {
    courseId: string;
    courseData: GenericObject;
    selectedLocationId: string;
    setSelectedLocationId: Dispatch<SetStateAction<string>>;
    onChange: (e: any, name: string) => void;
    rawOnChange: (name: string, value: any) => void;
    registrationData: any;
    validationResult: GenericObject;
    isLoading: boolean;
    isParticipantMinor: boolean;
}

const InternalForm = (props: Props) => {
    const { courseId, courseData, selectedLocationId, setSelectedLocationId, onChange, rawOnChange, registrationData, validationResult, isLoading, isParticipantMinor } = props;

    const { width } = useWindowSize();

    const [internalLoading, setInternalLoading] = useState(false);

    const sessionType = courseData?.sessionType ?? '';

    const [error, setError] = useState<DialogOption | null>(null);

    const step1Config: ValidatePersonStepProps = useMemo(() => {
        return {
            courseId,
            data: registrationData,
            validationResult,
            formPrefix: 'participant',
            sections: (['basic_data', 'tax_code', 'address', ...(isParticipantMinor ? [] : ['email', 'phone'])] as FormSection[]),
            messageCallback: (data: any) => setError((oldData: any) => oldData ?? { ...data, title: 'Dati partecipante - ' + data.title })
        };
    }, [setError, registrationData, validationResult, isParticipantMinor, courseId]);

    const step2Config: ValidatePersonStepProps = useMemo(() => {
        return {
            courseId,
            data: registrationData,
            validationResult,
            formPrefix: 'parent',
            sections: ['basic_data', 'tax_code', 'phone', 'email'],
            messageCallback: (data: any) => setError((oldData: any) => oldData ?? { ...data, title: 'Dati genitore 1 - ' + data.title })
        };
    }, [setError, registrationData, validationResult, courseId]);

    const step3Config: ValidatePersonStepProps = useMemo(() => {
        return {
            courseId,
            data: registrationData,
            validationResult,
            formPrefix: 'second_parent',
            sections: ['basic_data', 'phone', 'email'],
            messageCallback: (data: any) => setError((oldData: any) => oldData ?? { ...data, title: 'Dati genitore 2 - ' + data.title })
        };
    }, [setError, registrationData, validationResult, courseId]);


    const isParent2StepFilled = useMemo(() => {
        const fields = ['name', 'surname', 'phone', 'email'];

        if (!registrationData?.second_parent) return false;

        return fields.some(field => {
            return registrationData.second_parent[field] ?? false;
        });
    }, [registrationData]);

    useEffect(() => {
        if (!isParent2StepFilled && registrationData.second_parent) {
            rawOnChange('second_parent', null);
        }
    }, [rawOnChange, registrationData.second_parent, isParent2StepFilled]);

    const apiErrorCallback = useCallback((res: any) => {
        setError({
            title: 'Errore validazione dati',
            message: res?.errorDesc ?? 'Si è verificato un errore sconosciuto'
        });
    }, []);

    const validatePersonStep0 = useValidatePersonStep(step1Config);
    const validatePersonStep1 = useValidatePersonStep(step2Config);
    const validatePersonStep2 = useValidatePersonStep(step3Config);

    const validateCallback = useCallback(() => {
        setError(null);
        return new Promise((resolve: (value?: any) => void, reject) => {
            validatePersonStep0().then((res: any) => {
                if (isParticipantMinor) {
                    validatePersonStep1().then(() => {
                        if (!isParent2StepFilled) {
                            resolve();
                        } else {
                            validatePersonStep2().then(() => {
                                resolve();
                            }).catch(() => {
                                reject();
                            });
                        }
                    }).catch(() => {
                        reject();
                    });
                } else {
                    resolve();
                }
            }).catch(() => {
                reject();
            });
        });
    }, [isParticipantMinor, isParent2StepFilled, validatePersonStep0, validatePersonStep1, validatePersonStep2]);

    const {
        simulateRegistrationData,
        selectedSessions,
        setSelectedSessions,
        hasFoodService,
        getSessionsLabel,
        countSessions,
        countSessionsFoodService,
        countQueueSessions,
        isLoading: isSimulationLoading,
        isSelected,
        isFoodSelected,
        toPay,
        saving,
        additionalSaving
    } = useSimulateRegistration({ courseId, courseData, data: registrationData, sessionType, selectedLocationId, setSelectedLocationId, initialSelectedSessions: [], validateCallback, apiErrorCallback });

    const { handleSelect, handleFoodSelect } = useHandleSelect({ selectedSessions, setSelectedSessions });

    useEffect(() => {
        setSelectedSessions([]);
    }, [setSelectedSessions, courseId]);

    const isLoadingReal = isLoading || isSimulationLoading || internalLoading;
    const residenceTownLegalId = registrationData?.participant?.address?.town_legal_id ?? '';
    const realSessions = simulateRegistrationData?.discounts?.selected?.customPricing?.sessions ?? simulateRegistrationData?.sessions ?? [];
    const pricesNotAvailable = realSessions?.some((session: any) => {
        return session?.prices?.course?.actual?.price <= 0;
    });

    const [showMessageDialog] = useMessageDialog();
    const showGenericErrorDialog = useGenericErrorDialog();

    const history = useHistory();

    const performRegistration = useCallback(() => {
        if (!selectedLocationId) {
            showMessageDialog({
                title: 'Paese non selezionato',
                message: 'È necessario selezionare il paese d\'iscrizione.'
            });
        } else if ((countSessions + countQueueSessions) === 0 && !courseData.allowEmptyRegistration) {
            showMessageDialog({
                title: (sessionType === 'single_event' || sessionType === 'weekly_events') ? 'Giorni non selezionati' : 'Settimane non selezionate',
                message: 'È necessario selezionare ' + ((sessionType === 'single_event' || sessionType === 'weekly_events') ? 'i giorni a cui' : 'le settimane a cui') + ' desideri iscriverti.'
            });
        } else {
            setInternalLoading(true);

            const givenConsents = courseData.consents.map((c: any) => c.id);
            const newsletterConsents = {
                email: true,
                sms: true
            };

            const requestData = {
                ...registrationData,
                locationId: selectedLocationId,
                sessions: selectedSessions,
                consents: givenConsents,
                userNewsletterConsents: newsletterConsents,
                selectedReferralOption: 999
            };

            api.request('/admin/courses/' + courseId + '/registration', 'POST', requestData).then(() => {
                showMessageDialog({
                    title: 'Iscrizione completata!',
                    message: (
                        <>
                            <p style={{ marginTop: '0px' }}>
                                L'iscrizione è stata effettuata correttamente! È stata inviata una mail di conferma ai genitori.
                            </p>
                        </>
                    ),
                    onClose: () => {
                        history.push('/admin');
                    },
                });
            }).catch((res: any) => {
                showGenericErrorDialog(res);
            }).finally(() => {
                setInternalLoading(false);
            });
        }
    }, [courseData, courseId, history, registrationData, selectedLocationId, selectedSessions, showGenericErrorDialog, showMessageDialog, countQueueSessions, countSessions, sessionType]);

    return (
        <>
            {selectedLocationId !== '' && (
                <>
                    <PersonalData
                        onChange={onChange}
                        rawOnChange={rawOnChange}
                        registrationData={registrationData}
                        validationResult={validationResult}
                        isParticipantMinor={isParticipantMinor}
                    />
                </>
            )}

            {error ? (
                <Alert severity='warning' style={{ paddingBottom: '0px', marginBottom: '16px', marginTop: '16px' }}>
                    <AlertTitle>{error.title}</AlertTitle>
                    {error.message}
                </Alert>
            ) : (
                <>
                    <Paper style={{ padding: '16px', marginTop: '16px', marginBottom: '16px' }}>
                        {selectedLocationId && (
                            <>
                                <DiscountSelector
                                    discounts={simulateRegistrationData?.discounts}
                                    data={registrationData}
                                    rawOnChange={rawOnChange}
                                />

                                {pricesNotAvailable && (
                                    <Alert severity='info' style={{ paddingBottom: '0px', marginBottom: '8px' }}>
                                        <AlertTitle>Quote in fase di definizione</AlertTitle>
                                    </Alert>
                                )}

                                {selectedLocationId === '96572ecf-05d5-4171-9f44-5c5e92951a09' && (
                                    <Alert severity='info' style={{ paddingBottom: '0px', marginBottom: '14px' }}>
                                        <AlertTitle>Settimane 20-24 Giugno e 27 Giugno - 1 Luglio</AlertTitle>
                                        <p style={{ marginTop: 0 }}>
                                            Iscrizioni aperte solo a PRIMARIA E SECONDARIA
                                        </p>
                                        <AlertTitle>Settimane 4-8 e 11-15 luglio</AlertTitle>
                                        <p style={{ marginTop: 0 }}>
                                            Iscrizioni aperte solo all'INFANZIA
                                        </p>
                                    </Alert>
                                )}

                                {['f84361c3-6d02-4bcc-acc1-f35951719aed', '11848520-a8eb-496d-9cc7-ec64bf9d1f19', '9f606eee-2416-4018-bc27-89ccc10be8e5'].includes(selectedLocationId) && residenceTownLegalId === 'H176' && (
                                    <Alert severity='info' style={{ paddingBottom: '0px', marginBottom: '14px' }}>
                                        <AlertTitle>PER I RESIDENTI DI RANICA IL COMUNE ELARGIRÀ CONTRIBUTI DEDICATI</AlertTitle>
                                        <p style={{ marginTop: 0 }}>
                                            Le procedure di richiesta contributo residenti sono da presentare direttamente presso gli uffici comunali.
                                        </p>
                                    </Alert>
                                )}

                                <Typography variant='h4' style={{ fontSize: '1.4em', textAlign: 'center', margin: '8px', marginBottom: '10px' }}>
                                    Seleziona {(sessionType === 'single_event' || sessionType === 'weekly_events') ? 'i giorni a cui' : 'le settimane a cui'} desideri iscriverti
                                </Typography>

                                <div style={{ marginBottom: '32px' }}>
                                    {width > 700 ? (
                                        <TableSessionsSelector
                                            sessionType={sessionType}
                                            hasFoodService={hasFoodService}
                                            simulateRegistrationData={simulateRegistrationData}
                                            isSelected={isSelected}
                                            isFoodSelected={isFoodSelected}
                                            handleSelect={handleSelect}
                                            handleFoodSelect={handleFoodSelect}
                                            isSummer={(courseData?.season?.name ?? '').toLowerCase().includes('estiva')}
                                            isAdmin
                                        />
                                    ) : (
                                        <CardSessionsSelector
                                            sessionType={sessionType}
                                            hasFoodService={hasFoodService}
                                            simulateRegistrationData={simulateRegistrationData}
                                            isSelected={isSelected}
                                            isFoodSelected={isFoodSelected}
                                            handleSelect={handleSelect}
                                            handleFoodSelect={handleFoodSelect}
                                            isSummer={(courseData?.season?.name ?? '').toLowerCase().includes('estiva')}
                                            isAdmin
                                        />
                                    )}
                                </div>
                            </>
                        )}

                        <div>
                            {isLoadingReal ? (
                                <LoadingSimulationAlert />
                            ) : (
                                <>
                                    {!selectedLocationId && (
                                        <Alert severity='warning' style={{ paddingBottom: '0px' }}>
                                            <AlertTitle>Seleziona paese</AlertTitle>
                                            <p style={{ marginTop: '0px' }}>
                                                Seleziona il paese dove desideri iscriverti.
                                            </p>
                                        </Alert>
                                    )}

                                    {selectedLocationId && !countSessions && !countQueueSessions && (
                                        <Alert severity='warning' style={{ paddingBottom: '0px' }}>
                                            <AlertTitle>Seleziona {(sessionType === 'single_event' || sessionType === 'weekly_events') ? 'i giorni a cui' : 'le settimane a cui'} desideri iscriverti.</AlertTitle>
                                            <p style={{ marginTop: '0px' }}>
                                                È obbligatorio selezionare almeno {(sessionType === 'single_event' || sessionType === 'weekly_events') ? 'un giorno' : 'una settimana'}.
                                            </p>
                                        </Alert>
                                    )}

                                    {(countSessions + countQueueSessions) > 0 && (
                                        <SimulationRecapAlert
                                            simulateRegistrationData={simulateRegistrationData}
                                            countSessions={countSessions}
                                            countSessionsFoodService={countSessionsFoodService}
                                            countQueueSessions={countQueueSessions}
                                            toPay={toPay}
                                            saving={saving}
                                            additionalSaving={additionalSaving}
                                            getSessionsLabel={getSessionsLabel}
                                            pricesNotAvailable={pricesNotAvailable}
                                        />
                                    )}
                                </>
                            )}

                        </div>
                    </Paper>
                </>
            )}

            <Button onClick={performRegistration} disabled={isLoadingReal || error !== null} variant='contained' color='primary'>Conferma iscrizione</Button>


        </>
    );
};

export default InternalForm;
