import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import Button from '@material-ui/core/Button';

import { useMessageDialog } from '../../../../common/hooks/useMessageDialog/useMessageDialog';
import api from '../../../../common/utils/api';
import snackbar from '../../../../common/utils/snackbar';
import { setFromApi } from '../../../../redux/user/userSlice';
import PasswordExpiredDialog from './components/PasswordExpiredDialog/PasswordExpiredDialog';
import { ButtonContainer, FormContainer, Logo, ProgressPlaceholder, StyledProgress, StyledTextField, Title } from './styled';

const LoginPage = () => {
    const dispatch = useDispatch();

    const [credentials, setCredentials] = useState({
        username: '',
        password: '',
        rememberMe: false
    });

    const [showMessageDialog] = useMessageDialog();

    const [isLoading, setIsLoading] = useState(false);
    const [isPasswordExpiredDialogOpen, setIsPasswordExpiredDialogOpen] = useState(false);

    const handleLogin = useCallback((newPassword?: string) => {
        if (isLoading) return;

        if (credentials.password === newPassword) {
            return showMessageDialog({
                title: 'Errore di validazione',
                message: 'La nuova password non può essere uguale alla precedente.'
            });
        }

        setIsLoading(true);
        api.superUserLogin(credentials.username, credentials.password, newPassword).then(() => {
            // if login success, fetch the user data and save them in the Redux state
            if (newPassword) {
                snackbar.success('Password modificata con successo!');
            }
            api.request('/admin/auth/me').then(profile => {
                snackbar.success('Accesso effettuato con successo!');
                dispatch(setFromApi(profile));
            });
        }).catch((res) => {
            if (res?.error === 'password_expired') {
                setIsPasswordExpiredDialogOpen(true);
            }
        }).finally(() => {
            setIsLoading(false);
        });
    }, [credentials, dispatch, isLoading, showMessageDialog]);

    const handleInputChange = useCallback((field, e) => {
        const value = e.target.value;
        setCredentials((currentCredentials) => {
            return {...currentCredentials, [field]: value};
        });
    }, []);

    /*
    const handleRememberMeChange = useCallback((e) => {
        const checked = e.target.checked;
        setCredentials(currentCredentials => {
            return {...currentCredentials, rememberMe: checked};
        });
    }, []);

    const handleResetPasswordClick = useCallback((e: any) => {
        e.preventDefault();
        setIsResetPasswordDialogOpen(true);
    }, []);
    */

    const handleKeyDown = useCallback((e) => {
        if (e.keyCode === 13) {
            handleLogin();
        }
    }, [handleLogin]);

    return (
        <>
            <Logo src={process.env.PUBLIC_URL + '/logo512.png'} alt='logo' style={{maxHeight: '200px', maxWidth: '200px', marginTop: '32px'}} />
            <FormContainer>
                <Title variant='h5'>
                    Accesso amministratori
                </Title>

                <StyledTextField
                    label={'Email'}
                    variant='outlined'
                    onChange={(e) => handleInputChange('username', e)}
                    onKeyDown={handleKeyDown}
                    fullWidth
                    autoFocus
                />

                <StyledTextField
                    label={'Password'}
                    variant='outlined'
                    type='password'
                    onChange={(e) => handleInputChange('password', e)}
                    onKeyDown={handleKeyDown}
                    fullWidth
                />

                {/* <StyledLink href='#' onClick={(e: any) => handleResetPasswordClick(e)}>Password dimenticata?</StyledLink> */}

                {isLoading ?
                    <StyledProgress /> :
                    <ProgressPlaceholder />
                }

                <ButtonContainer>
                    <Button variant='contained' onClick={() => handleLogin()}>
                        Accedi
                    </Button>
                </ButtonContainer>
            </FormContainer>

            <PasswordExpiredDialog
                open={isPasswordExpiredDialogOpen}
                closeDialog={() => setIsPasswordExpiredDialogOpen(false)}
                isLoading={isLoading}
                handleLogin={handleLogin}
            />
        </>
    );
};

export default LoginPage;
