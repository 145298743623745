import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import useCompareSessionDate from '../../../../hooks/useCompareSessionDate/useCompareSessionDate';
import { SessionsSelectorProps } from '../../types';
import SessionRow from './components/SessionRow';
import { Container } from './styled';

interface Props extends SessionsSelectorProps {
    courseId?: string;
    showTownName?: boolean;
    schoolGrade?: number;
    tmpFixCorsiAdulti?: boolean;
    getSessionPriceWithOffset?: (sessionId: string) => number;
    selectedSessions?: any[];
    isAdmin?: boolean;
    pricesNotAvailable?: boolean;
    singleSessionEvent?: boolean;
    isSummer?: boolean;
}

const TableSessionsSelector = (props: Props) => {
    const { courseId, sessionType, hasFoodService, simulateRegistrationData, questionResponse, schoolGrade, tmpFixCorsiAdulti, getSessionPriceWithOffset, selectedSessions, isAdmin, singleSessionEvent, isSummer, ...rowProps } = props;
    const compareSessionDate = useCompareSessionDate({ sessionType });

    const sessions = simulateRegistrationData?.discounts?.selected?.customPricing?.sessions ?? simulateRegistrationData.sessions ?? [];

    const thereIsADifferentCourse = sessions.some((s: any) => {
        return courseId !== undefined && s.location.course.id !== courseId;
    });

    return (
        <Container>
            <TableContainer component={Paper}>
                <Table aria-label='simple table'>
                    {!singleSessionEvent && (
                        <TableHead>
                            <TableRow>
                                <TableCell>{sessionType === 'daily_week_events' ? 'Settimana' : 'Giorno'}</TableCell>
                                <TableCell align='center'>{isSummer ? 'Camp' : 'Corso'}</TableCell>
                                {hasFoodService && <TableCell align='center'>Pasti</TableCell>}
                            </TableRow>
                        </TableHead>
                    )}
                    <TableBody>
                        {sessions.sort(compareSessionDate).filter((s: any) => (((!s.question || s.question === questionResponse) && questionResponse !== s.disabledQuestion) || isAdmin) && (schoolGrade === undefined || ((!s.minimumSchoolGrade && !s.maximumSchoolGrade) || (schoolGrade >= s.minimumSchoolGrade && schoolGrade <= s.maximumSchoolGrade)))).map((session: any) => {
                            const isDifferentCourse = thereIsADifferentCourse;

                            return (
                                <SessionRow sessionType={sessionType} hasFoodService={hasFoodService} session={session} isDifferentCourse={isDifferentCourse} applyPriceOffset={tmpFixCorsiAdulti} getSessionPriceWithOffset={getSessionPriceWithOffset} singleSessionEvent={singleSessionEvent} {...rowProps} />
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

export default TableSessionsSelector;
