import { useCallback, useEffect, useMemo, useState } from 'react';

import { ButtonProps } from '@material-ui/core/Button';

import CustomDialogWrapper from '../../../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import { StyledTextField } from '../../../../../../../../common/components/CustomTextField/styled';
import { useMessageDialog } from '../../../../../../../../common/hooks/useMessageDialog/useMessageDialog';
import api from '../../../../../../../../common/utils/api';
import snackbar from '../../../../../../../../common/utils/snackbar';

interface Props {
    open: boolean;
    closeDialog: () => void;
}

const ChangePasswordDialog = (props: Props) => {
    const { open, closeDialog } = props;

    const [isLoading, setIsLoading] = useState(false);

    const [currentPassword, setCurrentPassword] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [isDirty, setIsDirty] = useState(false);

    const [showMessageDialog] = useMessageDialog();

    useEffect(() => {
        if (!open) return;

        setCurrentPassword('');
        setPassword('');
        setPasswordConfirm('');
    }, [open]);

    const validationError = useMemo(() => {
        if (password.length < 6) {
            return 'too_short';
        } else if (password !== passwordConfirm) {
            return 'mismatch';
        }

        return null;
    }, [password, passwordConfirm]);

    const handleChangePassword = useCallback(() => {
        setIsDirty(true);

        if (validationError === 'too_short') {
            return showMessageDialog({
                title: 'Errore di validazione',
                message: 'La nuova password deve essere lunga almeno 6 caratteri.'
            });
        } else if (validationError === 'mismatch') {
            return showMessageDialog({
                title: 'Errore di validazione',
                message: 'La password e la conferma non corrispondono.'
            });
        }

        setIsLoading(true);

        api.request('/admin/auth/password', 'PUT', { password: currentPassword, newPassword: password }).then(() => {
            snackbar.success('Password modificata con successo!');
            closeDialog();
        }).finally(() => {
            setIsLoading(false);
        });
    }, [closeDialog, currentPassword, password, showMessageDialog, validationError]);

    const handleKeyDown = useCallback((e) => {
        if (e.keyCode === 13) {
            handleChangePassword();
        }
    }, [handleChangePassword]);

    const buttons: ButtonProps[] = [
        {
            children: 'Salva',
            color: 'primary',
            onClick: () => handleChangePassword()
        },
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => closeDialog()
        },
    ];

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={'Modifica password'} buttons={buttons} isLoading={isLoading} maxWidth='sm' fullWidth>
            <StyledTextField
                label={'Password corrente'}
                variant='outlined'
                type='password'
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                onKeyDown={handleKeyDown}
                fullWidth
            />

            <StyledTextField
                label={'Nuova password'}
                variant='outlined'
                type='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={handleKeyDown}
                error={(!!password || isDirty) && validationError === 'too_short'}
                fullWidth
            />
            <StyledTextField
                label={'Conferma password'}
                variant='outlined'
                type='password'
                value={passwordConfirm}
                onChange={(e) => setPasswordConfirm(e.target.value)}
                onKeyDown={handleKeyDown}
                error={(!!passwordConfirm || isDirty) && !!validationError}
                fullWidth
            />
        </CustomDialogWrapper>
    );
};

export default ChangePasswordDialog;
