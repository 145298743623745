import { useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';

import useInternalLoader from '../../../../common/hooks/useInternalLoader/useExternalLoader';
import api from '../../../../common/utils/api';
import EmailRecipientsDialog from './components/EmailRecipientsDialog/EmailRecipientsDialog';
import { Title } from './styled';

const EmailsListPage = () => {
    const { width } = useWindowSize();
    const isMobile = width <= 700;

    const setIsLoading = useInternalLoader();
    const [data, setData] = useState<any[]>([]);

    const [isEmailRecipientsDialogOpen, setIsEmailRecipientsDialogOpen] = useState(false);
    const [selectedGroupId, setSelectedGroupId] = useState('');

    useEffect(() => {
        api.request('/admin/emails').then(res => {
            setData(res);
            setIsLoading(false);
        });
    }, [setIsLoading]);


    return (
        <>
            <Title isMobile={isMobile}>
                Email inviate
            </Title>

            <TableContainer component={Paper}>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell>Data</TableCell>
                            <TableCell>Oggetto</TableCell>
                            <TableCell>Inviata da</TableCell>
                            <TableCell>Email schedulate</TableCell>
                            <TableCell>Email inviate</TableCell>
                            <TableCell>Invii falliti</TableCell>
                            <TableCell>Email lette</TableCell>
                            <TableCell>Email con link visualizzati</TableCell>
                            <TableCell style={{width: '1px', whiteSpace: 'nowrap'}}>Azioni</TableCell>
                        </TableRow>
                    </TableHead>
                        <TableBody>
                        {data.map((email: any) => {
                            return (
                                <TableRow>
                                    <TableCell>{moment(email.createdAt).format('DD/MM/YYYY')}</TableCell>
                                    <TableCell>{email.subject}</TableCell>
                                    <TableCell>{email.superUser.name}</TableCell>
                                    <TableCell>{email.scheduledEmails}</TableCell>
                                    <TableCell>{email.sentEmails}</TableCell>
                                    <TableCell>{email.errorEmails}</TableCell>
                                    <TableCell>{email.readEmails}</TableCell>
                                    <TableCell>{email.openedEmails}</TableCell>
                                    <TableCell style={{width: '1px', whiteSpace: 'nowrap'}}>
                                        <Button size='small' variant='outlined' color='primary' onClick={() => {
                                            setSelectedGroupId(email.id);
                                            setIsEmailRecipientsDialogOpen(true);
                                        }}>
                                            Visualizza destinatari
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <EmailRecipientsDialog
                isOpen={isEmailRecipientsDialogOpen}
                onClose={() => {
                    setIsEmailRecipientsDialogOpen(false);
                }}
                emailGroupId={selectedGroupId}
            />
        </>
    );
};

export default EmailsListPage;
