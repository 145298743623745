import { configureStore } from '@reduxjs/toolkit';

import dialogs from './dialogs/dialogsSlice';
import globalDialogs from './globalDialogs/globalDialogsSlice';
import loaders from './loaders/loadersSlice';
import routing from './routing/routingSlice';
import user from './user/userSlice';

export default configureStore({
    reducer: {
        dialogs,
        globalDialogs,
        loaders,
        routing,
        user
    }
});
