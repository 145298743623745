import { useCallback, useEffect, useMemo, useState } from 'react';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import { useMessageDialog } from '../../../../../common/hooks/useMessageDialog/useMessageDialog';
import api from '../../../../../common/utils/api';
import { StyledTextField } from '../../registrationPage/styled';

interface Props {
    setCategory: (category: string) => void;
    data: any;
    resetForm: () => void;
}

const Step4 = ({ setCategory, data, resetForm }: Props) => {
    const [result, setResult] = useState<any | null>(null);

    const [isStep2, setIsStep2] = useState(false);
    const [email, setEmail] = useState('');

    const [consent1, setConsent1] = useState(false);
    const [consent2, setConsent2] = useState(false);

    const [showMessageDialog] = useMessageDialog();

    const [voucherStatus, setVoucherStatus] = useState(0);

    const maxWeeksPerPerson = useMemo(() => {
        return data.people.reduce((prev: number, curr: any) => {
            return Math.max(prev, curr.items.reduce((prevItem: number, currItem: any) => {
                return Math.max(prevItem, currItem.sessions);
            }, 0));
        }, 0);
    }, [data]);

    const sumWeeks = useMemo(() => {
        return data.people.reduce((prev: number, curr: any) => {
            return prev + curr.items.reduce((prevItem: number, currItem: any) => {
                return prevItem + currItem.sessions;
            }, 0);
        }, 0);
    }, [data]);

    const totalToPayWithoutDiscounts = useMemo(() => {
        if (!result) return 0;

        return result.people.reduce((prev: number, curr: any) => {
            return prev + curr.reduce((prevCourse: number, currCourse: any) => {
                return prevCourse + currCourse.weeks.reduce((prevWeek: number, currWeek: any) => {
                    return prevWeek + currWeek.basePrice;
                }, 0) + currCourse.food;
            }, 0);
        }, 0);
    }, [result]);

    const totalToPay = useMemo(() => {
        if (!result) return 0;

        return result.people.reduce((prev: number, curr: any) => {
            return prev + curr.reduce((prevCourse: number, currCourse: any) => {
                return prevCourse + currCourse.weeks.reduce((prevWeek: number, currWeek: any) => {
                    return prevWeek + currWeek.finalPrice;
                }, 0) + currCourse.food - (currCourse.discount ? currCourse.discount.value : 0);
            }, 0);
        }, 0);
    }, [result]);

    const multiWeekSaving = useMemo(() => {
        if (!result) return 0;

        return result.people.reduce((prev: number, curr: any) => {
            return prev + curr.reduce((prevCourse: number, currCourse: any) => {
                return prevCourse + currCourse.weeks.reduce((prevWeek: number, currWeek: any) => {
                    return prevWeek + (currWeek.basePrice - currWeek.partialWeekPrice);
                }, 0);
            }, 0);
        }, 0);
    }, [result]);

    const siblingsSaving = useMemo(() => {
        if (!result) return 0;

        return result.people.reduce((prev: number, curr: any) => {
            return prev + curr.reduce((prevCourse: number, currCourse: any) => {
                return prevCourse + currCourse.weeks.reduce((prevWeek: number, currWeek: any) => {
                    return prevWeek + (currWeek.partialWeekPrice - currWeek.finalPrice);
                }, 0);
            }, 0);
        }, 0);
    }, [result]);

    const getVoucher = useCallback(() => {
        if (!consent1 || !consent2) {
            showMessageDialog({
                title: 'Consensi richiesti',
                message: 'Per poter ottenere il voucher, è necessario dare entrambi i consensi'
            });
            return;
        }

        if (!result) return;

        setVoucherStatus(1);

        api.request('/quote/' + (result?.id) + '/voucher', 'POST', { email }).then((res: any) => {
            const status = res.status ?? '';
            const amount = res.amount ?? 0.0;

            setVoucherStatus(0);

            if (status === 'user_has_registration') {
                showMessageDialog({
                    title: 'Impossibile generare il voucher',
                    message: 'Questo piccolo sconto di ' + parseFloat(amount) + '€ è un benvenuto a chi non ha mai fatto un\'iscrizione! Ma non preoccuparti... riserviamo periodicamente sconti dedicati a chi è con noi da tempo quindi continua ad usare la mail con cui hai effettuato le scorse iscrizioni per non perdere futuri privilegi!'
                });
                return;
            } else if (status === 'voucher_already_received') {
                showMessageDialog({
                    title: 'Impossibile generare il voucher',
                    message: 'Hai già ricevuto questo codice sconto in passato.'
                });
                return;
            } else if (status === 'ok') {
                setVoucherStatus(2);
            } else {
                showMessageDialog({
                    title: 'Impossibile generare il voucher',
                    message: 'Si è verificato un errore sconosciuto.'
                });
                return;
            }
        }).catch(() => {
            setVoucherStatus(0);
        });
    }, [consent1, consent2, showMessageDialog, result, email]);

    useEffect(() => {
        api.request('/quote', 'POST', data).then((res: any) => {
            setResult(res);
        });
    }, [data]);

    if (maxWeeksPerPerson > 1 && !isStep2) {
        return (
            <div style={{ maxWidth: '650px', margin: '0 auto' }}>
                <Typography variant='h2' style={{ fontSize: '25px', fontFamily: '\'Libre Franklin\', sans-serif', textAlign: 'left', margin: '8px 0' }}>
                    <b>Il tuo preventivo è quasi pronto!</b><br />
                    <b>Un'ultima cosa...</b>
                </Typography>

                <p>
                    Il tuo preventivo è calcolato sulle scontische standard.<br />
                    Potresti accedere ad ulteriori sconti nel caso in cui:

                    <ol>
                        <li>tu sia già iscritto ad un camp / corso nella stagione in corso</li>
                        <li>tu abbia a disposizione un buono sconto</li>
                    </ol>
                </p>

                <p>
                    Al momento della effettiva iscrizione il nostro sistema calcolerà automaticamente sconti aggiuntivi!
                </p>

                <Button variant='outlined' color='primary' onClick={() => setIsStep2(true)} style={{ display: 'block', margin: '18px auto', padding: '10px 15px' }}>
                    Ottieni preventivo
                </Button>
            </div>
        );
    }

    if (!result) {
        return <CircularProgress style={{ margin: '0 auto', display: 'block' }} />;
    }

    return (
        <>
            {/*
            <Typography variant='h2' style={{fontSize: '3em', textAlign: 'center', margin: '8px'}}>
                Ecco il tuo preventivo
            </Typography>
            */}

            <Alert severity='info' style={{ color: '#000000', width: 'fit-content', margin: '0 auto' }} icon={false}>

                <div style={{ maxWidth: '650px', margin: '0 auto 18px', textAlign: 'left' }}>
                    <p style={{ fontSize: '1.35em', marginTop: '0' }}>
                        Il totale da pagare è di{' '}

                        {totalToPayWithoutDiscounts === totalToPay ? (
                            <>
                                <span style={{ marginLeft: '4px', fontWeight: 500, fontSize: '1.45em' }}>€{totalToPay}</span>
                            </>
                        ) : (
                            <>
                                <span style={{ marginLeft: '4px', fontSize: '1.45em' }}><s>€{totalToPayWithoutDiscounts}</s></span>.
                                <span style={{ color: '#0CA789', marginLeft: '4px', fontWeight: 500, fontSize: '1.45em' }}>€{totalToPay}</span>
                            </>
                        )}


                    </p>

                    {multiWeekSaving > 0 && (
                        <p style={{ fontSize: '1.15em' }}>
                            Fantastico! Iscrivendoti a più settimane stai risparmiando <b>€{multiWeekSaving}</b>!
                        </p>
                    )}

                    {!(multiWeekSaving > 0) && maxWeeksPerPerson < 2 && (
                        <p style={{ fontSize: '1.15em' }}>
                            Sai che iscrivendo {(result?.people ?? []).length === 1 ? 'tuo figlio' : 'i tuoi figli'} a più settimane avresti diritto a scontistiche dedicate?
                        </p>
                    )}

                    {siblingsSaving > 0 && (
                        <p style={{ fontSize: '1.15em' }}>
                            Grazie allo sconto fratelli stai risparmiando <b>€{siblingsSaving}</b>!
                        </p>
                    )}
                </div>

                <div style={{ maxWidth: '650px', margin: '18px auto 0', textAlign: 'left' }}>
                    {result.people.map((person: any, personIndex: number) => {
                        const shouldShowTitle = result.people.length > 1 || person.length > 1;

                        if (sumWeeks < 2) return null;

                        return person.map((course: any, courseIndex: number) => {
                            return (
                                <div style={{ marginBottom: '0px' }}>
                                    <p style={{ fontSize: '1.4em', marginBottom: '4px' }}>
                                        {shouldShowTitle && (
                                            <>
                                                {result.people.length > 1 && (
                                                    <>
                                                        Figlio {personIndex + 1} - {' '}
                                                    </>
                                                )}

                                                <small>
                                                    {course.course} {course.town}
                                                </small>
                                            </>
                                        )}
                                    </p>

                                    {course.weeks.map((week: any, weekIndex: number) => {
                                        return (
                                            <p style={{ margin: 0 }}>
                                                settimana {weekIndex + 1}:{' '}
                                                {week.basePrice === week.finalPrice ? (
                                                    <b>€{week.basePrice}</b>
                                                ) : (
                                                    <>
                                                        <s>€{week.basePrice}</s> <span style={{ color: '#0CA789' }}><b>€{week.finalPrice}</b></span>
                                                    </>
                                                )}
                                            </p>
                                        );
                                    })}

                                    {course.food > 0 && (
                                        <p style={{ margin: 0, marginTop: '4px' }}>
                                            costo pasti: <b>€{course.food}</b>
                                        </p>
                                    )}

                                    {course.discount && (
                                        <p style={{ margin: 0, marginTop: '4px' }}>
                                            sconto {course.discount.discount.nameDescription}: <b>- €{course.discount.value}</b>
                                        </p>
                                    )}
                                </div>
                            );
                        });
                    })}
                </div>

            </Alert>

            <div style={{ width: 'fit-content', margin: '0px auto 36px' }}>
                <Button variant='contained' color='primary' onClick={() => resetForm()} style={{ display: 'block', margin: '14px 0', width: '100%', padding: '10px 15px' }}>
                    Effettua un nuovo preventivo
                </Button>

                <Button variant='contained' color='primary' onClick={() => window.open('https://epuntos.it/form-generico/', '_blank')} style={{ display: 'block', margin: '14px 0', width: '100%', padding: '10px 15px', backgroundColor: '#0CA789', color: '#ffffff' }}>
                    Iscriviti
                </Button>
            </div>

            {voucherStatus < 2 ? (
                <div style={{ maxWidth: '650px', margin: '4px auto', textAlign: 'left' }}>
                    <p style={{ fontSize: '1.35em', fontWeight: 500, marginBottom: 0 }}>
                        🤫 Psst! Sei un nuovo utente?
                    </p>
                    <p style={{ fontSize: '1.15em', marginTop: '4px' }}>
                        Allora abbiamo uno sconto di benvenuto per te!<br />Lasciaci la mail e ti invieremo il voucher.
                    </p>

                    <StyledTextField
                        label={'Indirizzo email'}
                        variant='outlined'
                        type='email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        fullWidth
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                color='primary'
                                style={{ padding: '4px' }}
                                checked={consent1}
                                onChange={(e) => {
                                    setConsent1(e.target.checked);
                                }}
                            />
                        }
                        style={{ marginTop: '4px', marginLeft: '-4px', marginBottom: '8px' }}
                        label={'Dichiaro di accettare la Privacy Policy'}
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                color='primary'
                                style={{ padding: '4px' }}
                                checked={consent2}
                                onChange={(e) => {
                                    setConsent2(e.target.checked);
                                }}
                            />
                        }
                        style={{ marginLeft: '-4px' }}
                        label={'Dichiaro di voler ricevere news, offerte e aggiornamenti da Educazione.Sport'}
                    />

                    <Button variant='contained' color='primary' onClick={() => getVoucher()} disabled={voucherStatus > 0} style={{ display: 'block', margin: '14px auto', padding: '10px 15px' }}>
                        Ottieni voucher
                    </Button>
                </div>
            ) : (
                <Alert severity='success' style={{ maxWidth: '650px', margin: '0 auto' }}>
                    <AlertTitle>Voucher inviato con successo!</AlertTitle>
                    Controlla la tua posta elettronica.
                </Alert>
            )}

        </>
    );
};

export default Step4;
