import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import useInternalLoader from '../../../../common/hooks/useInternalLoader/useExternalLoader';
import api from '../../../../common/utils/api';
import { ReduxState } from '../../../../redux/types';
import InfoBox from './components/InfoBox/InfoBox';

const HomePage = () => {
    const history = useHistory();

    const setIsLoading = useInternalLoader(false);

    const [registrationCount, setRegistrationCount] = useState(0);
    const [missingDocument, setMissingDocuments] = useState(0);
    const [toPay, setToPay] = useState(0.0);

    const userInfo = useSelector((state: ReduxState) => state.user);
    const isUserLoggedIn = userInfo.id.length > 0;

    useEffect(() => {
        if (!isUserLoggedIn) return;

        setIsLoading(true);
        api.request('/documents').then((documentRes: any) => {
            const missingDocumentsCount = documentRes.reduce((prev: number, row: any) => {
                const countMissing = row.documents.filter((document: any) => document.status === 'expiring' || document.status === 'missing').length;
                return prev + countMissing;
            }, 0);
            setMissingDocuments(missingDocumentsCount);
        }).finally(() => {
            api.request('/registrations').then((registrationRes: any) => {
                setRegistrationCount(registrationRes.length);

                const totalToPay = registrationRes.reduce((prev: number, row: any) => {
                    return prev + parseFloat(row.cost) - parseFloat(row.paid);
                }, 0.0);

                setToPay(totalToPay);
            }).finally(() => {
                setIsLoading(false);
            });
        });
    }, [isUserLoggedIn, setIsLoading]);

    return (
        <div>
            <Typography variant='h2' style={{fontSize: '4em'}}>
                Ciao!{/* Cristian!*/} &nbsp;
                <span style={{fontSize: '0.65em'}}>Benvenuto nella piattaforma di gestione iscrizioni di Educazione.Sport.</span>
            </Typography>

            {isUserLoggedIn && (
                <div style={{padding: '8px', display: 'flex', flexWrap: 'wrap', marginTop: '8px'}}>
                    <InfoBox style={{backgroundColor: '#cbf7c7', minWidth: '300px', margin: '12px 8px'}}>
                        <>
                            {registrationCount} <br/>
                            <span style={{fontSize: '0.65em'}}>{registrationCount === 1 ? 'iscrizione' : 'iscrizioni'} recente</span>
                        </>
                    </InfoBox>
                    <InfoBox style={{backgroundColor: missingDocument === 0 ? '#cbf7c7' : '#ffe0b5', minWidth: '300px', margin: '12px 8px'}}>
                        <>
                            {missingDocument} <br/>
                            <span style={{fontSize: '0.65em'}}>{missingDocument === 1 ? 'documento' : 'documenti'} da caricare</span>
                        </>
                    </InfoBox>
                    <InfoBox style={{backgroundColor: '#ffe0b5', minWidth: '300px', margin: '12px 8px'}}>
                        <>
                            €{toPay.toFixed(2)} <br/>
                            <span style={{fontSize: '0.65em'}}>da pagare</span>
                        </>
                    </InfoBox>
                </div>
            )}

            <div style={{margin: '16px'}}>
                {/*
                <div style={{margin: '24px 16px 4px'}}>
                    <Typography variant='h2' style={{fontSize: '3em'}}>
                        Corsi consigliati
                    </Typography>
                </div>
                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                    {coursesData.slice(0, 3).map(course => {
                        const { image } = course;
                        const imageUrl = image ? (process.env.REACT_APP_API_HOST + '/assets/' + image.id) : undefined;

                        return (
                            <CourseCard id={course.id} name={course.name} description={course.description} image={imageUrl} />
                        );
                    })}
                </div>
                */}
                <Button size='large' variant='outlined' color='primary' style={{margin: '0 auto', display: 'block', fontSize: '1.6em'}} onClick={() => history.push('/courses')}>Iscriviti ad un corso</Button>
            </div>
        </div>
    );
};

export default HomePage;
