import { BaseSyntheticEvent, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import md5 from 'md5';

import { ReduxState } from '../../../../../../../../redux/types';
import { setFromApi } from '../../../../../../../../redux/user/userSlice';
import { Container, EmailContainer, FlexContainer, FlexPusher, NameContainer, ProfileMenuButton, StyledAvatar } from '../../styled';
import ChangePasswordDialog from '../ChangePasswordDialog/ChangePasswordDialog';

const LoggedUserInfo = () => {
    const userInfo = useSelector((state: ReduxState) => state.user);

    const [menuAnchor, setMenuAnchor] = useState(null);

    const [isChangePasswordDialogOpen, setIsChangePasswordDialogOpen] = useState(false);

    const dispatch = useDispatch();

    const openProfileMenu = (e: BaseSyntheticEvent) => {
        setMenuAnchor(e.currentTarget);
    };

    const closeProfileMenu = () => {
        setMenuAnchor(null);
    };

    const handleLogout = useCallback(() => {
        const emptyUser = {
            id: '',
            email: '',
        };

        dispatch(setFromApi(emptyUser));
        localStorage.setItem('access_token', '');
    }, [dispatch]);

    const getGravatar = (email: string) => {
        const emailMd5 = md5(email.toLowerCase());

        return 'https://gravatar.com/avatar/' + emailMd5 + '?d=mp';
    };

    return (
        <Container>
            <FlexContainer>
                <StyledAvatar alt='profile' src={getGravatar(userInfo.email)} />
                <FlexPusher />
            </FlexContainer>

            <NameContainer>
            {' '}
            </NameContainer>

            <EmailContainer variant='caption' gutterBottom>
                {userInfo.email}
            </EmailContainer>

            <ProfileMenuButton edge='start' color='inherit' onClick={openProfileMenu} >
                <ExpandMoreIcon />
            </ProfileMenuButton>

            <Menu anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={closeProfileMenu} keepMounted >
                <MenuItem onClick={() => {
                    setIsChangePasswordDialogOpen(true);
                    closeProfileMenu();
                }}>
                    Modifica password
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                    Logout
                </MenuItem>
            </Menu>

            <ChangePasswordDialog
                open={isChangePasswordDialogOpen}
                closeDialog={() => setIsChangePasswordDialogOpen(false)}
            />
        </Container>
    );
};

export default LoggedUserInfo;
