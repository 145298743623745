import { useMemo } from 'react';

import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import GenericObject from '../../../../../../typesAdditional/GenericObject';

interface Props {
    simulateRegistrationData: GenericObject;
    countSessions: number;
    countSessionsFoodService: number;
    countQueueSessions: number;
    toPay: number;
    saving: number;
    additionalSaving: number;
    getSessionsLabel: (count: number) => string;
    pricesNotAvailable?: boolean;
    allowEmptyRegistration?: boolean;
}

const SimulationRecapAlert = (props: Props) => {
    const { simulateRegistrationData, countSessions, countSessionsFoodService, countQueueSessions, toPay, saving, additionalSaving, getSessionsLabel, pricesNotAvailable, allowEmptyRegistration } = props;

    const discountDesc = useMemo(() => {
        const appliedDiscount = simulateRegistrationData?.discounts?.selected;

        if (!appliedDiscount) return '';

        if (appliedDiscount.conditionType === 'already_coursist') {
            return 'tesserati';
        } else if (appliedDiscount.conditionType === 'multi_course') {
            return 'multi coroo';
        } else if (appliedDiscount.conditionType === 'resident') {
            return 'residenti';
        } else if (appliedDiscount.description) {
            return '"' + appliedDiscount.description + '"';
        } else {
            return 'selezionata';
        }
    }, [simulateRegistrationData]);

    const partialPayment = useMemo(() => {
        return simulateRegistrationData?.savedRegistration?.paid ?? 0;
    }, [simulateRegistrationData]);

    const isPartiallyPaid = useMemo(() => {
        return partialPayment > 0;
    }, [partialPayment]);

    const finalToPay = useMemo(() => {
        return Math.max(0, toPay - partialPayment);
    }, [partialPayment, toPay]);

    if (simulateRegistrationData?.isWelfareRegistration && simulateRegistrationData?.welfareStatus?.status !== 'ok') {
        const status = simulateRegistrationData?.welfareStatus?.status;
        const amount = simulateRegistrationData?.welfareStatus?.amount;
        const target = simulateRegistrationData?.welfareStatus?.target;

        return (
            <Alert severity='warning' style={{ paddingBottom: '0px' }}>
                <AlertTitle>Credito welfare {status === 'welfare_amount_not_reached' ? 'non completamente utilizzato' : 'superato'}</AlertTitle>

                {status === 'welfare_amount_not_reached' && (
                    <>
                        <p style={{ marginTop: '6px' }}>
                            Per finalizzare l'iscrizione, devi utilizzare tutto il tuo credito welfare.
                        </p>

                        <p style={{ marginTop: '0px' }}>
                            Il costo delle settimane selezionate è di <b>€{amount}</b>; devi raggiungere <b>€{target}</b> per effettuare l'iscrizione.
                        </p>
                    </>
                )}

                {status === 'welfare_amount_exceeded' && (
                    <>
                        <p style={{ marginTop: '6px', marginBottom: '4px' }}>
                            Non puoi effettuare un'iscrizione per un importo superiore al tuo credito welfare.
                        </p>

                        <p style={{ marginTop: '0px' }}>
                            Il costo delle settimane selezionate è di <b>€{amount}</b>; il tuo credito welfare è pari a <b>€{target}</b>.
                        </p>

                        <p style={{ marginTop: '6px' }}>
                            Puoi aggiungere delle settimane ulteriori in un successivo momento.
                        </p>
                    </>
                )}

            </Alert>
        )
    }

    return (
        <Alert severity='info' style={{ paddingBottom: '0px' }}>
            <AlertTitle>Riepilogo iscrizione</AlertTitle>
            {(countSessions > 0 || ((countSessions + countQueueSessions) === 0 && allowEmptyRegistration)) && (
                <p style={{ marginTop: '0px' }}>
                    Stai effettuando l'iscrizione per <b>{countSessions + (simulateRegistrationData?.suggestedResult?.session ?? 0)} {getSessionsLabel(countSessions + (simulateRegistrationData?.suggestedResult?.session ?? 0))}</b>
                    {countSessionsFoodService > 0 && (
                        <>
                            , di cui <b>{countSessionsFoodService}</b> con servizio pasti
                        </>
                    )}.
                </p>
            )}

            {countQueueSessions > 0 && (
                <p style={{ marginTop: '0px' }}>
                    {countSessions > 0 ? 'Inoltre, verrai' : 'Verrai'}
                    {' '}inserito in lista d'attesa per <b>{countQueueSessions} {getSessionsLabel(countQueueSessions)}</b> selezionat{getSessionsLabel(countQueueSessions)?.slice(-1)}.
                </p>
            )}

            {pricesNotAvailable ? (
                <>
                    <p style={{ marginTop: '0px' }}>
                        Quote in fase di definizione.
                    </p>
                </>
            ) : (
                <>
                    {simulateRegistrationData?.isWelfareRegistration && toPay === simulateRegistrationData?.welfareStatus.target ? (
                        <p style={{ marginTop: '0px' }}>
                            La quota del camp è già stata saldata sulla piattaforma <b>Welfare Jointly</b>.
                        </p>
                    ) : (
                        <>
                            {toPay > 0 && (
                                <p style={{ marginTop: '0px' }}>
                                    {isPartiallyPaid ? 'Il costo complessivo dell\'iscrizione' : 'Il totale da pagare'} è di <b>€{toPay.toFixed(2)}</b>{countQueueSessions > 0 ? ', escluse le iscrizioni in lista d\'attesa' : ''}.
                                </p>
                            )}

                            {isPartiallyPaid && (
                                <p style={{ marginTop: '0px' }}>
                                    Hai già pagato <b>€{partialPayment.toFixed(2)}</b>, ti rimangono da pagare <b>€{finalToPay.toFixed(2)}</b>.
                                </p>
                            )}

                            {saving > 0 && (
                                <p style={{ marginTop: '0px' }}>
                                    Iscrivendoti a più {getSessionsLabel(2)} stai risparmiando <b>€{saving.toFixed(2)}</b>!
                                </p>
                            )}

                            {additionalSaving > 0 && (
                                <p style={{ marginTop: '0px' }}>
                                    {saving > 0 ? 'Inoltre, stai' : 'Stai'} risparmiando <b>€{additionalSaving.toFixed(2)}</b> grazie alla convenzione {discountDesc}!
                                </p>
                            )}

                            {([...(simulateRegistrationData?.discounts?.additionals ?? []), ...(simulateRegistrationData?.tempAdditionals ?? [])]).map((d: any) => {
                                const amount = parseFloat(d.amount);

                                return (
                                    <p style={{ marginTop: '0px' }}>
                                        {d.conditionType === '3_siblings' && (
                                            <>
                                                Stai {(additionalSaving > 0 || saving > 0) ? 'inoltre ' : ''}risparmiando <b>€{Math.abs(amount).toFixed(2)}</b> grazie allo sconto fratelli.
                                            </>
                                        )}

                                        {d.conditionType === 'custom_single_registration' && (
                                            <>
                                                Ti è inoltre stato applicato {amount > 0 ? 'uno sconto' : 'un sovrapprezzo'} aggiuntivo di <b>€{Math.abs(amount).toFixed(2)}</b>.
                                            </>
                                        )}

                                        {d.conditionType === 'voucher' && (
                                            <>
                                                {(additionalSaving > 0 || saving > 0) ? (
                                                    <>
                                                        È infine stato applicato un codice sconto del valore di <b>€{Math.abs(amount).toFixed(2)}</b>.
                                                    </>
                                                ) : (
                                                    <>
                                                        È già stato applicato un codice sconto del valore di <b>€{Math.abs(amount).toFixed(2)}</b>.
                                                    </>
                                                )}

                                            </>
                                        )}
                                    </p>
                                );
                            })}
                        </>
                    )}


                </>
            )}


        </Alert>
    );
};

export default SimulationRecapAlert;
