import Button from '@material-ui/core/Button';
import styled from 'styled-components';

const GreenButton = styled(Button)`
    background-color: #2e7d32;
    border-color: #1e7e34;
    color: #ffffff;
    height: fit-content;
    margin: auto;
    &:hover {
        background-color: #339f4b;
    }
`;

export default GreenButton;
