import { Redirect } from 'react-router-dom';

import { faCalendarCheck, faEnvelope, faHome, faReceipt, faTag, faUserPlus, faUsers, faHandsHelping } from '@fortawesome/free-solid-svg-icons';

import { SuperUserRoute } from '../../typesAdditional/Route';
import EmailsListPage from './pages/emailsListPage/EmailsListPage';
import EmailUserListPage from './pages/emailUserListPage/EmailUserListPage';
import LoginPage from './pages/loginPage/LoginPage';
import NewEmailPage from './pages/newEmailPage/NewEmailPage';
import NewRegistrationPage from './pages/newRegistrationPage/NewRegistrationPage';
import PeopleListPage from './pages/peopleListPage/PeopleListPage';
import ReceiptsListPage from './pages/receiptsListPage/receiptsListPage';
import ReceiptsRequestsPage from './pages/receiptsRequestsPage/ReceiptsRequestsPage';
import RegistrationLocationPage from './pages/registrationLocationPage/RegistrationLocationPage';
import RegistrationsPage from './pages/registrationsPage/RegistrationsPage';
import VouchersPage from './pages/vouchersPage/VouchersPage';
import WelfarePage from './pages/welfarePage/WelfarePage';

const routes: SuperUserRoute[] = [
    {
        name: 'Home',
        icon: faHome,
        path: '/',
        component: <Redirect to={'/admin/registrations'} />,
        requireAuth: true,
        showInSidebar: false,
    },
    {
        name: 'Elenco utenti',
        icon: faUsers,
        path: '/people',
        component: <PeopleListPage />,
        requireAuth: true
    },
    {
        name: 'Aggiungi iscrizione',
        icon: faUserPlus,
        path: '/registrations/new',
        component: <NewRegistrationPage />,
        requireAuth: true,
        needAdmin: true
    },
    {
        name: 'Gestione iscrizioni',
        icon: faCalendarCheck,
        path: '/registrations',
        component: <RegistrationsPage />,
        requireAuth: true
    },
    {
        name: 'Gestione iscrizioni',
        path: '/registrations/:courseId/locations/:locationId',
        component: <RegistrationLocationPage />,
        showInSidebar: false
    },
    {
        name: 'Voucher',
        icon: faTag,
        path: '/vouchers',
        component: <VouchersPage />,
        needAdmin: true,
    },
    {
        name: 'Welfare',
        icon: faHandsHelping,
        path: '/welfare',
        component: <WelfarePage />,
        needAdmin: true,
    },
    {
        name: 'Invio email',
        icon: faEnvelope,
        path: '/emails',
        needAdmin: true,
        subRoutes: [
            {
                name: 'Nuova email',
                path: '/emails/new',
                needAdmin: true,
                component: <NewEmailPage />
            },
            {
                name: 'Storico invii',
                path: '/emails/list',
                needAdmin: true,
                component: <EmailsListPage />
            },
            {
                name: 'Lista contatti',
                path: '/emails/users',
                needAdmin: true,
                component: <EmailUserListPage />
            }
        ]
    },
    {
        name: 'Ricevute',
        icon: faReceipt,
        path: '/receipts',
        needAdmin: true,
        subRoutes: [
            {
                name: 'Da emettere',
                path: '/receipts/requests',
                needAdmin: true,
                component: <ReceiptsRequestsPage />
            },
            {
                name: 'Emesse',
                path: '/receipts/list',
                needAdmin: true,
                component: <ReceiptsListPage />
            }
        ]
    },
    {
        name: 'Login',
        path: '/login',
        component: <LoginPage />,
        showSidebar: false,
        showInSidebar: false,
        canAuth: false,
        requireAuth: false
    }
];

const defaultAttributes: Partial<SuperUserRoute> = {
    exactPath: true,
    exactPathSidebar: true,
    showSidebar: true,
    showInSidebar: true,
    requireAuth: true,
    canAuth: true
};

const setDefaultAttributes = (routess: SuperUserRoute[]): SuperUserRoute[] => {
    return routess.map(route => {
        const realRoute = route.subRoutes ? {
            ...route,
            subRoutes: setDefaultAttributes(route.subRoutes)
        } : route;

        return { ...defaultAttributes, ...realRoute };
    });
};

// default attributes and real attributes are merged
const finalRoutes = setDefaultAttributes(routes);

export default finalRoutes;
