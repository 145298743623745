import { useCallback, useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';

import { ButtonProps } from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import CustomTextField from '../../../../../../common/components/CustomTextField/CustomTextField';
import { useMessageDialog } from '../../../../../../common/hooks/useMessageDialog/useMessageDialog';
import api from '../../../../../../common/utils/api';
import PaymentCard from '../../../../../admin/pages/registrationLocationPage/components/PaymentsDialog/components/PaymentCard/PaymentCard';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    selectedRegistrationId?: string;
}

const ReceiptRequestDialog = (props: Props) => {
    const { isOpen, onClose, selectedRegistrationId } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState<any>({});
    const [selectedPayments, setSelectedPayments] = useState<any>([]);

    const [showMessageDialog] = useMessageDialog();

    const selectPayment = useCallback((paymentId: string, flag: boolean) => {
        if (flag) {
            setSelectedPayments((payments: any) => {
                return [...new Set([...payments, paymentId])];
            });
        } else {
            setSelectedPayments((payments: any) => {
                return payments.filter((p: any) => p !== paymentId);
            });
        }
    }, []);

    useEffect(() => {
        if (!selectedRegistrationId || !isOpen) return;

        setSelectedPayments([]);
        setIsLoading(true);
        api.request('/receipts/registrations/' + selectedRegistrationId).then(res => {
            setData(res);

            const readyPayments = (res?.payments ?? []).filter((p: any) => p.status === 'ready');

            if (readyPayments.length === 1) {
                selectPayment(readyPayments[0].id, true);
            }
        }).finally(() => {
            setIsLoading(false);
        });
    }, [selectPayment, isOpen, selectedRegistrationId]);

    const createRequest = useCallback(() => {
        if (selectedPayments.length < 1) {
            showMessageDialog({
                title: 'Pagamento non selezionato',
                message: (
                    <>
                        È necessario selezionare i pagamenti per i quali si desidera richiedere la ricevuta.
                    </>
                )
            });
            return;
        }

        setIsLoading(true);
        api.request('/receipts/requests', 'POST', {
            registrationId: selectedRegistrationId,
            payments: selectedPayments,
            taxCode: data?.person?.taxCode ?? '',
            name: data?.person?.name ?? '',
            taxCode2: data?.person?.taxCode2 ?? null,
            name2: data?.person?.name2 ?? null
        }).then((res: any) => {
            showMessageDialog({
                title: 'Richiesta ricevuta avvenuta correttamente',
                message: (
                    <>
                        A breve riceverà la sua ricevuta via mail, potrà inoltre visualizzarla in qualsiasi momento nella sezione "Le mie iscrizioni" della sua Area privata.
                    </>
                )
            });
            onClose();
        }).finally(() => {
            setIsLoading(false);
        });
    }, [showMessageDialog, selectedRegistrationId, selectedPayments, data, onClose]);

    const { width } = useWindowSize();
    const isMobile = width < 700;

    const buttons: ButtonProps[] = [
        {
            children: 'Conferma',
            color: 'primary',
            onClick: () => createRequest()
        },
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => onClose()
        }
    ];

    return (
        <CustomDialogWrapper fullScreen={isMobile} open={isOpen} onClose={onClose} title={'Richiedi ricevuta'} buttons={buttons} isLoading={isLoading} maxWidth='sm' fullWidth contentProps={{}} >
            <Typography variant='body1' style={{margin: '0 4px 16px'}}>
                Assicurati che i dati fiscali siano corretti, seleziona i pagamenti per i quali vuoi richiedere la ricevuta, e conferma la richiesta.
            </Typography>

            <CustomTextField
                label={'Nome e cognome'}
                variant='outlined'
                value={data?.person?.name ?? ''}
                keepMounted={true}
                onChange={(e: any) => {
                    setData((oldParams: any) => {
                        const person = {...oldParams.person, name: e.target.value};
                        return {...oldParams, person};
                    });
                }}
                // disabled={isLoading}
            />

            <CustomTextField
                label={'Codice fiscale'}
                variant='outlined'
                value={data?.person?.taxCode ?? ''}
                keepMounted={true}
                onChange={(e: any) => {
                    setData((oldParams: any) => {
                        const person = {...oldParams.person, taxCode: e.target.value};
                        return {...oldParams, person};
                    });
                }}
                style={{marginBottom: '20px'}}
                // disabled={isLoading}
            />

            <CustomTextField
                label={'Nome e cognome genitore 2 (facoltativo)'}
                variant='outlined'
                value={data?.person?.name2 ?? ''}
                keepMounted={true}
                onChange={(e: any) => {
                    setData((oldParams: any) => {
                        const person = {...oldParams.person, name2: e.target.value};
                        return {...oldParams, person};
                    });
                }}
                // disabled={isLoading}
            />

            <CustomTextField
                label={'Codice fiscale genitore 2 (facoltativo)'}
                variant='outlined'
                value={data?.person?.taxCode2 ?? ''}
                keepMounted={true}
                onChange={(e: any) => {
                    setData((oldParams: any) => {
                        const person = {...oldParams.person, taxCode2: e.target.value};
                        return {...oldParams, person};
                    });
                }}
                style={{marginBottom: '20px'}}
                // disabled={isLoading}
            />

            {(data?.payments ?? []).map((payment: any) => {
                return (
                    <PaymentCard
                        key={payment.id}
                        payment={payment}
                        selectedPayments={selectedPayments}
                        selectPayment={selectPayment}
                        checkboxDisabled={payment.status !== 'ready'}
                        disabledReason={payment.status === 'pending' ? 'La ricevuta è in fase di emissione' : 'La ricevuta è già stata emessa'}
                        userMode
                    />
                );
            })}
        </CustomDialogWrapper>
    );
};

export default ReceiptRequestDialog;
