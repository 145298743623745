import GenericObject from '../../../../../../../typesAdditional/GenericObject';

interface Params {
    selectedSessions: GenericObject[];
    setSelectedSessions: (sessions: GenericObject[]) => void;
}

const useHandleSelect = (params: Params) => {
    const { selectedSessions, setSelectedSessions } = params;

    const handleSelect = (sessionId: string, value: boolean) => {
        const clonedSessions = [...selectedSessions];

        if (value === false) {
            setSelectedSessions(clonedSessions.filter(s => s.id !== sessionId));
        } else {
            clonedSessions.push({
                id: sessionId,
                foodService: false
            });
            setSelectedSessions(clonedSessions);
        }
    };

    const handleFoodSelect = (sessionId: string, value: boolean) => {
        const clonedSessions = [...selectedSessions];

        const currSession = clonedSessions.find(s => s.id === sessionId);

        if (currSession) {
            currSession.foodService = value;
            setSelectedSessions(clonedSessions);
        } else {
            clonedSessions.push({
                id: sessionId,
                foodService: value
            });
            setSelectedSessions(clonedSessions);
        }
    };

    return {
        handleSelect,
        handleFoodSelect
    };
};

export default useHandleSelect;
