import Hidden from '@material-ui/core/Hidden';

import { DrawerContainer, StyledDrawer, StyledSwipeableDrawer, StyledToolbar } from './styled';

interface Props {
    show: boolean;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    content: any;
}

const Drawer = (props: Props) => {
    const { show, isOpen, setIsOpen, content } = props;

    const width = window.location.pathname.startsWith('/admin') ? 260 : 240;

    return (
        <DrawerContainer show={show} width={width}>
            <Hidden mdUp implementation='css'>
                {/* swipeable drawer, for small screens */}
                <StyledSwipeableDrawer
                    variant='temporary'
                    anchor='left'
                    open={isOpen}
                    onOpen={() => setIsOpen(true)}
                    onClose={() => setIsOpen(false)}
                    ModalProps={{
                        keepMounted: true
                    }}
                    width={width}
                    disableBackdropTransition
                >
                    {content}
                </StyledSwipeableDrawer>
            </Hidden>

            <Hidden smDown implementation='css'>
                <StyledDrawer variant='permanent' width={width}>
                    <StyledToolbar />
                    <div style={{display: 'flex', flexDirection: 'column', height: '100%', overflow: 'auto'}}>
                        {content}
                    </div>

                </StyledDrawer>
            </Hidden>
        </DrawerContainer>
    );
};

export default Drawer;
