import useCompareSessionDate from '../../../../hooks/useCompareSessionDate/useCompareSessionDate';
import { SessionsSelectorProps } from '../../types';
import SessionCard from './components/SessionCard';

interface Props extends SessionsSelectorProps {
    courseId?: string;
    showTownName?: boolean;
    schoolGrade?: number;
    tmpFixCorsiAdulti?: boolean;
    getSessionPriceWithOffset?: (sessionId: string) => number;
    selectedSessions?: any[];
    isAdmin?: boolean;
    pricesNotAvailable?: boolean;
    singleSessionEvent?: boolean;
    isSummer?: boolean;
}

const CardSessionsSelector = (props: Props) => {
    const { courseId, sessionType, hasFoodService, simulateRegistrationData, questionResponse, schoolGrade, tmpFixCorsiAdulti, getSessionPriceWithOffset, selectedSessions, isAdmin, singleSessionEvent, ...cardProps } = props;
    const compareSessionDate = useCompareSessionDate({ sessionType });

    const sessions = simulateRegistrationData?.discounts?.selected?.customPricing?.sessions ?? simulateRegistrationData.sessions ?? [];

    const thereIsADifferentCourse = sessions.some((s: any) => {
        return courseId !== undefined && s.location.course.id !== courseId;
    });

    return (
        <>
            {sessions.sort(compareSessionDate).filter((s: any) => (((!s.question || s.question === questionResponse) && questionResponse !== s.disabledQuestion) || isAdmin) && (schoolGrade === undefined || ((!s.minimumSchoolGrade && !s.maximumSchoolGrade) || (schoolGrade >= s.minimumSchoolGrade && schoolGrade <= s.maximumSchoolGrade)))).map((session: any) => {
                const isDifferentCourse = thereIsADifferentCourse;

                return <SessionCard sessionType={sessionType} hasFoodService={hasFoodService} session={session} isDifferentCourse={isDifferentCourse} applyPriceOffset={tmpFixCorsiAdulti} getSessionPriceWithOffset={getSessionPriceWithOffset} singleSessionEvent={singleSessionEvent} {...cardProps} />;
            })}
        </>
    );
};

export default CardSessionsSelector;
