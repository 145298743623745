import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import _ from 'lodash';

import useFirstParentDataValidator from '../../../entryPoints/public/pages/registrationPage/hooks/useFirstParentDataValidator/useFirstParentDataValidator';
import useParticipantDataValidator from '../../../entryPoints/public/pages/registrationPage/hooks/useParticipantDataValidator/useParticipantDataValidator';
import api from '../../utils/api';

interface Params {
    courseId: string;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
}

const useRegistrationLogic = (params: Params) => {
    const { courseId, setIsLoading } = params;

    const isAdmin = window.location.pathname.startsWith('/admin');

    const [courseData, setCourseData] = useState<any>(null);

    const [isParticipantMinor, setIsParticipantMinor] = useState(true);
    const [registrationData, setRegistrationData] = useState<any>({});

    const [oldDateOfBirth, setOldDateOfBirth] = useState('');
    const [oldParentDateOfBirth, setOldParentDateOfBirth] = useState('');

    const history = useHistory();

    const participantValidationResult = useParticipantDataValidator(courseId, registrationData);
    const firstParentValidationResult = useFirstParentDataValidator(courseId, registrationData);

    useEffect(() => {
        if (setIsParticipantMinor && participantValidationResult.taxCode) {
            setIsParticipantMinor(participantValidationResult.taxCode.isMinor);
        }
    }, [participantValidationResult.taxCode, setIsParticipantMinor]);

    const validationResult = useMemo(() => {
        return {
            participant: participantValidationResult,
            parent: firstParentValidationResult
        };
    }, [participantValidationResult, firstParentValidationResult]);

    const rawOnChange = useCallback((name: string, value: any) => {
        setRegistrationData((d: any) => {
            const newObj = {...d};
            _.set(newObj, name, value);
            return newObj;
        });
    }, []);

    const onChange = useCallback((e: any, name: string) => {
        rawOnChange(name, e.target.value);
    }, [rawOnChange]);

    useEffect(() => {
        api.request((isAdmin ? '/admin' : '') + '/courses/' + courseId).then(res => {
            setCourseData(res);
            setIsParticipantMinor(res.minimumAge < 18);
        }).catch(() => {
            history.push('/');
        }).finally(() => {
            setIsLoading(false);
        });
    }, [isAdmin, setIsLoading, courseId, history]);

    useEffect(() => {
        const shouldUpdate = (registrationData?.participant?.date_of_birth ?? '') === oldDateOfBirth;
        const newComputedDateOfBirth = participantValidationResult?.taxCode?.dateOfBirth;

        if (newComputedDateOfBirth && shouldUpdate) {
            setOldDateOfBirth(newComputedDateOfBirth);
            rawOnChange('participant.date_of_birth', newComputedDateOfBirth);
        }
    }, [rawOnChange, oldDateOfBirth, participantValidationResult?.taxCode?.dateOfBirth, registrationData?.participant?.date_of_birth]);

    useEffect(() => {
        const shouldUpdate = (registrationData?.parent?.date_of_birth ?? '') === oldParentDateOfBirth;
        const newComputedDateOfBirth = firstParentValidationResult?.taxCode?.dateOfBirth;

        if (newComputedDateOfBirth && shouldUpdate) {
            setOldParentDateOfBirth(newComputedDateOfBirth);
            rawOnChange('parent.date_of_birth', newComputedDateOfBirth);
        }
    }, [rawOnChange, oldParentDateOfBirth, firstParentValidationResult?.taxCode?.dateOfBirth, registrationData?.parent?.date_of_birth]);


    /*
    const fieldsHookPropsParticipant: FieldsHookProps = useMemo(() => {
        return {
            data: registrationData,
            validationResult,
            formPrefix: 'participant',
            sections: formSectionsParticipant,
        };
    }, [registrationData, validationResult]);
    */

    return {
        courseData,
        onChange,
        rawOnChange,
        registrationData,
        setIsParticipantMinor,
        validationResult,
        isParticipantMinor
    };
};

export default useRegistrationLogic;
