import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Link from '@material-ui/core/Link';

import useGenericErrorDialog from '../../../../../../../common/hooks/useGenericErrorDialog/useGenericErrorDialog';
import { useMessageDialog } from '../../../../../../../common/hooks/useMessageDialog/useMessageDialog';
import api from '../../../../../../../common/utils/api';
import { dataLayerPush } from '../../../../../../../common/utils/gtm';
import { ReduxState } from '../../../../../../../redux/types';
import GenericObject from '../../../../../../../typesAdditional/GenericObject';

interface Params {
    courseId: string;
    courseData: GenericObject;
    data: GenericObject;
    selectedLocationId: string;
    selectedSessions: GenericObject[];
    consents: GenericObject[];
    givenConsents: string[];
    newsletterConsents: GenericObject;
    selectedReferralOption: string | number | null;
    setIsLoading: (value: boolean) => void;
    setIsLoadingFinal: (value: boolean) => void;
    resetRegistrationData: () => void;
    price: number;
    saving: number;
    preselectedLocation: GenericObject;
    gtmStepPush: (step: number, data: any) => void;
    suggestedSessions: GenericObject[];
    welfareData: any;
}

const useInternalNextStep = (params: Params) => {
    const { courseId, courseData, data, selectedLocationId, selectedSessions, consents, givenConsents, newsletterConsents, selectedReferralOption, setIsLoading, setIsLoadingFinal, resetRegistrationData, price, saving, preselectedLocation, gtmStepPush, suggestedSessions, welfareData } = params;

    const { isIframe } = useSelector((state: ReduxState) => state.routing);

    const [showMessageDialog, closeMessageDialog] = useMessageDialog();
    const showGenericErrorDialog = useGenericErrorDialog();
    const history = useHistory();

    const campTown = (courseData?.locations?.find((l: any) => l.id === selectedLocationId) ?? preselectedLocation)?.town?.name;

    const analyticsData = useMemo(() => {
        return {
            visitorLoggedIn: '1',
            summer_camp: courseData?.name ?? '',
            camp_town: campTown ?? '',
            student_country: 'Italy',
            student_city: data?.participant?.address?.town_name ?? '',
            student_zip: data?.participant?.address?.zip ?? '',
            subscription_weeks: selectedSessions.length,
            subscription_price: price,
            subscription_savings: saving
        };
    }, [courseData, campTown, data, selectedSessions, price, saving]);

    useEffect(() => {
        const push = () => {
            gtmStepPush(5, analyticsData);
        };

        if (!price) {
            const timeOut = setTimeout(() => {
                push();
            }, 5000);

            return () => clearTimeout(timeOut);
        } else {
            push();
        }
    }, [analyticsData, gtmStepPush, price, preselectedLocation]);

    const handleResetRegistration = useCallback((e: React.SyntheticEvent) => {
        resetRegistrationData();
        closeMessageDialog(true);
        e.preventDefault();
        return false;
    }, [closeMessageDialog, resetRegistrationData]);

    return useCallback(() => {
        if (givenConsents.length !== consents.length) {
            showMessageDialog({
                title: 'Impossibile proseguire',
                message: 'È necessario accettare tutti i consensi obbligatori.'
            });

            dataLayerPush({
                event: 'signup_unsuccessful',
                error_message: 'È necessario accettare tutti i consensi obbligatori.',
                ...analyticsData
            });
        } else if (selectedReferralOption === '' && courseData.showReferralQuestion) {
            showMessageDialog({
                title: 'Impossibile proseguire',
                message: 'È necessario selezionare una voce dal menu "vi ho scoperti tramite".'
            });

            dataLayerPush({
                event: 'signup_unsuccessful',
                error_message: 'È necessario selezionare una voce dal menu "vi ho scoperti tramite".',
                ...analyticsData
            });
        } else {
            setIsLoading(true);
            setIsLoadingFinal(true);

            const requestData = {
                ...data,
                locationId: selectedLocationId,
                sessions: selectedSessions,
                consents: givenConsents,
                suggestedSessions,
                userNewsletterConsents: newsletterConsents,
                selectedReferralOption,
                welfareData
            };

            api.request('/courses/' + courseId + '/registration', 'POST', requestData).then((res) => {
                showMessageDialog({
                    title: 'Iscrizione completata!',
                    message: (
                        <>
                            {res.isWaitingList ? (
                                <>
                                    <p style={{ marginTop: '0px' }}>
                                        Hai completato correttamente l'iscrizione ma purtroppo i posti disponibili per il Camp "{courseData?.name} {courseData?.season.year + 1}" sono esauriti.
                                    </p>
                                    <p style={{ marginTop: '4px', marginBottom: '0px' }}>
                                        Ti abbiamo inviato una mail con tutti i dettagli inerenti alla lista d'attesa nella quale sei stato inserito.
                                    </p>
                                </>
                            ) : (
                                <>
                                    <p style={{ marginTop: '0px' }}>
                                        L'iscrizione è stata effettuata correttamente! Ti abbiamo inviato una mail di conferma.
                                    </p>
                                    {data.parent && (
                                        <p style={{ marginBottom: '0px' }}>
                                            Se vuoi iscrivere un altro figlio <Link href='#' onClick={handleResetRegistration}>clicca qui</Link>.
                                        </p>
                                    )}
                                </>
                            )}
                        </>
                    ),
                    onClose: () => {
                        if (!isIframe) {
                            history.push('/');
                        }
                    },
                    notDismissible: isIframe
                });

                dataLayerPush({
                    event: 'subscription_complete',
                    ...analyticsData
                });
            }).catch((res: any) => {
                showGenericErrorDialog(res);
            }).finally(() => {
                setIsLoading(false);
                setIsLoadingFinal(false);
            });
        }
    }, [welfareData, courseData, suggestedSessions, analyticsData, isIframe, data, history, showGenericErrorDialog, showMessageDialog, newsletterConsents, selectedReferralOption, courseId, selectedLocationId, consents, givenConsents, handleResetRegistration, selectedSessions, setIsLoading, setIsLoadingFinal]);
};

export default useInternalNextStep;
