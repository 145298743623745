import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';

import useSearchParams from '../../common/hooks/useSearchParams/useSearchParams';
import AdminApp from '../../entryPoints/admin/AdminApp';
import PublicApp from '../../entryPoints/public/PublicApp';
import { setInitialPath, setIsIframe } from '../../redux/routing/routingSlice';

const MainRouter = () => {
    const { pathname } = useLocation();
    const { iframe } = useSearchParams();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setIsIframe(iframe !== undefined));
        dispatch(setInitialPath(pathname));
    }, [dispatch, iframe, pathname]);

    return (
        <Switch>
            <Route path='/admin' component={AdminApp} />
            <Route path='*' component={PublicApp} />
        </Switch>
    );
};

export default MainRouter;
