import React, { useEffect, useRef } from 'react';

import { Editor } from '@tinymce/tinymce-react';

interface Props {
    setEditorRef: (ref: any) => void;
}

const TinyEditor = (props: Props) => {
    const { setEditorRef } = props;

    const editorRef = useRef<any>(null);

    useEffect(() => {
        setEditorRef(editorRef);
    }, [editorRef, setEditorRef]);

     return (
         <>
             <Editor
                tinymceScriptSrc='/tinymce/tinymce.min.js'
                onInit={(evt, editor) => editorRef.current = editor}
                initialValue=''
                init={{
                    plugins: 'paste importcss searchreplace autolink visualblocks visualchars fullscreen image link table charmap hr pagebreak nonbreaking toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons code',
                    imagetools_cors_hosts: ['picsum.photos'],
                    menubar: 'file edit view insert format tools table help',
                    toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | charmap emoticons | image link',
                    toolbar_sticky: false,
                    autosave_ask_before_unload: true,
                    autosave_interval: '30s',
                    autosave_prefix: '{path}{query}-{id}-',
                    autosave_restore_when_empty: false,
                    autosave_retention: '2m',
                    image_advtab: true,
                    importcss_append: true,
                    template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
                    template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
                    height: 400,
                    image_caption: true,
                    // quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                    quickbars_insert_toolbar: '',
                    quickbars_selection_toolbar: '',
                    noneditable_noneditable_class: 'mceNonEditable',
                    toolbar_mode: 'sliding',
                    contextmenu: 'link image imagetools table',
                    skin: 'oxide',
                    content_css: 'default',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    language: 'it',
                }}
            />
         </>
   );
};

export default TinyEditor;
