import routes from '../../routes';
import Logo from './components/Logo/Logo';
import RoutesList from './components/RoutesList/RoutesList';
import UserInfoSection from './components/UserInfoSection/UserInfoSection';

const DrawerContent = () => {
    return (
        <>
            <UserInfoSection />
            <RoutesList routes={routes} depth={0} />
            <Logo />
        </>
    );
};

export default DrawerContent;
