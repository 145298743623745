import { useEffect, useState } from 'react';

import { ButtonProps } from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import 'moment/locale/it';

import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import api from '../../../../../../common/utils/api';
import { TYPES_MAP } from '../../VouchersPage';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    voucherId: string;
}

const VoucherDetailsDialog = (props: Props) => {
    const { isOpen, onClose, voucherId } = props;

    const [isLoading, setIsLoading] = useState(false);

    const [data, setData] = useState<any>({});

    useEffect(() => {
        if (!voucherId) {
            return;
        }

        setData({});
        setIsLoading(true);
        api.request('/admin/voucher_groups/' + voucherId).then((res: any) => {
            setData(res);
            setIsLoading(false);
        });
    }, [voucherId]);

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => onClose()
        },
    ];

    return (
        <>
            <CustomDialogWrapper open={isOpen} onClose={() => onClose()} title={'Dettagli voucher'} buttons={buttons} isLoading={isLoading} maxWidth='xl' fullWidth >
                <TableContainer component={Paper}>
                    <Table size='small'>
                        <TableBody>
                            <TableRow>
                                <TableCell><b>Tipologia</b></TableCell>
                                <TableCell>{data ? TYPES_MAP[data.target as keyof(typeof TYPES_MAP)] : ''}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><b>Codice</b></TableCell>
                                <TableCell>{data ? (data.code ? data.code : 'n.d.') : ''}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <div style={{height: '16px'}} />

                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Nome</TableCell>
                                <TableCell>Cognome</TableCell>
                                <TableCell>Email</TableCell>

                                {(data?.target ?? '') !== 'everyone' && (
                                    <TableCell>Codice</TableCell>
                                )}

                                <TableCell>Importo voucher</TableCell>
                                <TableCell>Importo utilizzato</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(data?.vouchers ?? []).map((v: any, idx: number) => {
                                return (
                                    <TableRow style={{backgroundColor: (parseFloat(v.amountUsed) === parseFloat(v.amount)) ? '#edf7ed' : (parseFloat(v.amountUsed) === 0 ? '#fdecea' : '#fff4e5')}} >
                                        <TableCell>{idx + 1}</TableCell>
                                        <TableCell>{v.user?.person?.name ?? ''}</TableCell>
                                        <TableCell>{v.user?.person?.surname ?? ''}</TableCell>
                                        <TableCell>{v.user?.email ?? ''}</TableCell>

                                        {(data?.target ?? '') !== 'everyone' && (
                                            <TableCell>{v.code}</TableCell>
                                        )}

                                        <TableCell>€ {parseFloat(v.amount).toFixed(2)}</TableCell>
                                        <TableCell>€ {parseFloat(v.amountUsed).toFixed(2)}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CustomDialogWrapper>
        </>
    );
};

export default VoucherDetailsDialog;
