import { useCallback, useEffect, useState } from 'react';

import CustomTextField from '../../../../../../common/components/CustomTextField/CustomTextField';
import { useMessageDialog } from '../../../../../../common/hooks/useMessageDialog/useMessageDialog';
import GenericObject from '../../../../../../typesAdditional/GenericObject';
import PlaceOfBirthAutocomplete from './components/PlaceOfBirthAutocomplete/PlaceOfBirthAutocomplete';

interface Props {
    data: GenericObject;
    prefix: string;
    value: string;
    disabled: boolean;
    rawOnChange: (field: string, value: any) => void;
}

const PlaceOfBirthField = (props: Props) => {
    const { data, prefix, value, disabled, rawOnChange } = props;
    const [showMessageDialog] = useMessageDialog();

    const isAdmin = window.location.pathname.startsWith('/admin');

    const useAutoComplete = !data[prefix]?.tax_code && isAdmin;

    const [initialValue] = useState(data[prefix]?.place_of_birth_legal_id);

    const handleOnClick = useCallback(() => {
        if (disabled) return;

        const messages = {
            empty: 'Il campo non è modificabile manualmente, ma verrà compilato automaticamente dopo avere inserito il codice fiscale.',
            filled: (
                <>
                    <p style={{ margin: 0, marginBottom: '8px' }}>Il campo è stato compilato automaticamente in base al codice fiscale inserito.</p>
                    <p style={{ margin: 0 }}>Se non è corretto, controlla di avere digitato correttamente il codice fiscale.</p>
                </>
            )
        };

        showMessageDialog({
            title: 'Luogo di nascita',
            message: messages[value ? 'filled' : 'empty']
        });
    }, [disabled, showMessageDialog, value]);

    useEffect(() => {
        if (useAutoComplete) {
            rawOnChange(prefix + '.place_of_birth_legal_id', initialValue);
            rawOnChange(prefix + '.placeOfBirthName', '');
        } else {
            rawOnChange(prefix + '.place_of_birth_legal_id', undefined);
        }
    }, [rawOnChange, initialValue, prefix, useAutoComplete]);

    if (useAutoComplete) {
        return (
            <PlaceOfBirthAutocomplete data={data} formPrefix={prefix} rawOnChange={rawOnChange} />
        );
    }

    return (
        <CustomTextField
            label='Luogo di nascita'
            value={value}
            variant='outlined'
            data={data}
            inputProps={{ readOnly: true, disabled: true, tabIndex: -1 }}
            style={{ flex: 2 }}
            onClick={handleOnClick}
            disabled={disabled}
        />
    );
};

export default PlaceOfBirthField;
