import { faCalendarCheck, faHome, faIdCard, faRunning } from '@fortawesome/free-solid-svg-icons';

import Route from '../../typesAdditional/Route';
import NewCoursesPage from './pages/coursesPage/components/NewCoursesPage/NewCoursesPage';
import CoursesPage from './pages/coursesPage/CoursesPage';
import DocumentsPage from './pages/documentsPage/DocumentsPage';
import HomePage from './pages/homePage/HomePage';
import LoginDialogPage from './pages/loginDialogPage/LoginDialogPage';
import LoginPage from './pages/loginPage/LoginPage';
import QuotePage from './pages/quotePage/QuotePage';
import RegistrationPageWrapper from './pages/registrationPage/RegistrationPageWrapper';
import RegistrationPpsMs from './pages/registrationPpsMs/RegistrationPpsMs';
import RegistrationsListPage from './pages/registrationsListPage/RegistrationsListPage';

const routes: Route[] = [
    {
        name: 'Home',
        icon: faHome,
        path: '/',
        component: <HomePage />,
    },
    {
        name: 'Tutti i corsi',
        icon: faRunning,
        path: '/courses',
        component: <CoursesPage />,
        exactPathSidebar: false
    },
    {
        name: 'Tutti i corsi',
        icon: faRunning,
        path: '/courses/:categoryId',
        component: <NewCoursesPage />,
        showInSidebar: false
    },
    {
        name: 'Registrazione',
        path: '/courses/:id/registration',
        component: <RegistrationPageWrapper />,
        showInSidebar: false
    },
    {
        name: 'Registrazione PPS e MS',
        path: '/courses/:id/pps_ms_registration',
        component: <RegistrationPpsMs />,
        showInSidebar: false
    },
    {
        name: 'Le mie iscrizioni',
        icon: faCalendarCheck,
        path: '/registrations',
        component: <RegistrationsListPage />,
    },
    {
        name: 'I miei documenti',
        icon: faIdCard,
        path: '/documents',
        component: <DocumentsPage />,
    },
    /*{
        name: 'Newsletter',
        icon: faEnvelope,
        path: '/newsletter',
        workInProgress: true,
        component: <p style={{fontSize: '2em'}}>In questa pagina si potrò abilitare e disabilitare la ricezione della newsletter.</p>,
    },*/
    {
        name: 'Preventivo',
        path: '/quote',
        component: <QuotePage />,
        showInSidebar: false
    },
    {
        name: 'Login dialog',
        path: '/login_dialog',
        component: <LoginDialogPage />,
        showInSidebar: false
    },
    {
        name: 'Login',
        path: '/login',
        component: <LoginPage />,
        showInSidebar: false
    }
];

const defaultAttributes: Partial<Route> = {
    exactPath: true,
    exactPathSidebar: true,
    showSidebar: true,
    showInSidebar: true,
    requireAuth: true,
    canAuth: true
};

const finalRoutes = routes.map(route => {
    // default attributes and real attributes are merged
    return {...defaultAttributes, ...route};
});

export default finalRoutes;
