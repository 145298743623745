import { useCallback, useMemo } from 'react';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import moment from 'moment';

import GenericObject from '../../../../../../../../typesAdditional/GenericObject';

interface Props {
    payment: GenericObject;
    deletePayment?: (payment: GenericObject) => void;
    userMode?: boolean;
    checkboxDisabled?: boolean;
    disabledReason?: string;
    selectedPayments?: string[];
    selectPayment?: (paymentId: string, flag: boolean) => void;
}

const PaymentCard = (props: Props) => {
    const { payment, deletePayment, userMode, checkboxDisabled, selectPayment, selectedPayments, disabledReason } = props;

    const internalCheck = useMemo(() => {
        return (selectedPayments ?? []).includes(payment.id);
    }, [payment, selectedPayments]);

    const setInternalCheck = useCallback((flag: boolean) => {
        if (selectPayment) selectPayment(payment.id, flag);
    }, [payment, selectPayment]);

    return (
        <Card variant='outlined' style={{marginBottom: '12px'}}>
            <CardContent>
                <div style={{display: 'flex'}}>
                    {userMode && (
                        <div style={{textAlign: 'right', alignItems: 'center', display: 'flex'}}>
                            <div>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color='primary'
                                            style={{padding: '4px'}}
                                            checked={internalCheck}
                                            onChange={(e) => {
                                                setInternalCheck(e.target.checked);
                                            }}
                                        />
                                    }
                                    label=''
                                    style={{marginLeft: '-4px', marginTop: '2px'}}
                                    disabled={checkboxDisabled}
                                />
                            </div>
                        </div>
                    )}
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <div style={{flex: 1, display: 'flex', alignItems: 'center', fontSize: '1.75em'}}>
                            € {parseFloat(payment.amount).toFixed(2)}
                        </div>
                        {!userMode && (
                            <>
                                <div style={{flex: 1, display: 'flex', alignItems: 'center', marginTop: '4px'}}>
                                    {payment.masterPayment.method && (
                                        <>
                                            {payment.masterPayment.method.name}
                                        </>
                                    )}
                                    {payment.masterPayment.welfareProvider && (
                                        <>
                                            Welfare {payment.masterPayment.welfareProvider.name}
                                        </>
                                    )}
                                </div>
                                <div style={{flex: 1, display: 'flex', alignItems: 'center'}}>
                                    {moment(payment.masterPayment.createdAt).format(userMode ? 'DD/MM/YYYY' : 'DD/MM/YYYY H:mm:ss')}
                                </div>
                            </>
                        )}
                    </div>
                    <div style={{flexGrow: 1}} />
                    {!userMode && payment.masterPayment.superUser && (
                        <div style={{textAlign: 'right', alignItems: 'center', display: 'flex'}}>
                            <div>
                                <div style={{fontWeight: 'bold', marginBottom: '2px'}}>
                                    Registrato da
                                </div>
                                {payment.masterPayment.superUser.name}<br/>
                                {payment.masterPayment.superUser.email}
                            </div>
                        </div>
                    )}
                    {userMode && (
                        <div style={{textAlign: 'right', alignItems: 'center', display: 'flex'}}>
                            <div>
                                <div style={{flex: 1, display: 'flex', alignItems: 'center', marginTop: '4px'}}>
                                    {payment.masterPayment.method && (
                                        <>
                                            {payment.masterPayment.method.name}
                                        </>
                                    )}
                                    {payment.masterPayment.welfareProvider && (
                                        <>
                                            Welfare {payment.masterPayment.welfareProvider.name}
                                        </>
                                    )}
                                </div>
                                <div style={{flex: 1, display: 'flex', alignItems: 'center'}}>
                                    {moment(payment.masterPayment.createdAt).format(userMode ? 'DD/MM/YYYY' : 'DD/MM/YYYY H:mm:ss')}
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                {checkboxDisabled && disabledReason && (
                    <p style={{fontSize: '1em', marginBottom: '0px'}}>{disabledReason}</p>
                )}

                {deletePayment && !payment.masterPayment.welfareProvider && (
                    <div style={{textAlign: 'right'}}>
                        <Button onClick={() => deletePayment(payment)} color={'secondary'} variant={'outlined'} size='small' style={{marginTop: '8px', marginBottom: '-8px'}}>
                            Elimina pagamento
                        </Button>
                    </div>
                )}

                {payment.deletedAt && !userMode && (
                    <p style={{margin: '12px 0 0'}}>
                        Eliminato da <b>{payment?.superUserDeletedBy?.name}</b>{' '}
                        ({payment?.superUserDeletedBy?.email}){' '}
                        in data <b>{moment(payment?.deletedAt).format('DD/MM/YYYY H:mm:ss')}</b>.
                    </p>
                )}

            </CardContent>
        </Card>
    );
};

export default PaymentCard;
