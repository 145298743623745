import { useState } from 'react';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import EventIcon from '@material-ui/icons/Event';

import { Asset, Document } from '../../types';
import ApproveDialog from './components/ApproveDialog/ApproveDialog';
import FileUploader from './components/FileUploader/FileUploader';
import FormAsset from './components/FormAsset/FormAsset';
import UnapproveDialog from './components/UnapproveDialog/UnapproveDialog';

interface Props {
    initialAssets?: Asset[];
    document: Document;
    handleAssetChange: (documentId: string, assets: any[]) => void;
    isAdmin?: boolean;
    refreshRegistrations?: () => void;
    closeDialog?: () => void;
}

const backgroundColors = {
    ok: '#edf7ed',
    ok_not_required: '#edf7ed',
    reviewing: '#edf7ed',
    expiring: '#fff4e5',
    missing: '',
    missing_pre_birthday: ''
};

const backgroundColorsAdmin = {
    ok: backgroundColors.ok,
    ok_not_required: backgroundColors.ok,
    reviewing: '#fff4e5',
    expiring: backgroundColors.expiring,
    missing: '#fdecea',
    missing_pre_birthday: '#fdecea'
};

const showAdminButtons = true;

const DocumentCard = (props: Props) => {
    const { initialAssets, document, handleAssetChange, isAdmin, refreshRegistrations, closeDialog } = props;
    const { id: documentId, documentType, status } = document;
    const { id, name, description, formAsset } = documentType;

    const [isUnapproveDialogOpen, setIsUnapproveDialogOpen] = useState(false);
    const [isApproveDialogOpen, setIsApproveDialogOpen] = useState(false);

    return (
        <>
            <Card variant='outlined' style={{ margin: '16px 0 0', backgroundColor: (isAdmin ? backgroundColorsAdmin[status] : backgroundColors[status]) ?? '' }}>
                <CardContent>
                    <Typography variant='h5' component='h2'>
                        {name}
                    </Typography>

                    <Typography variant='body2' style={{ marginTop: '4px', marginLeft: '1px' }}>
                        {description}
                    </Typography>

                    {(status === 'ok' || (status === 'reviewing' && !isAdmin)) && (
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '6px' }}>
                            <CheckCircleOutlineIcon style={{ marginRight: '4px' }} /> Documento già caricato!
                        </div>
                    )}

                    {(status === 'ok_not_required') && (
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '6px' }}>
                            <CheckCircleOutlineIcon style={{ marginRight: '4px' }} /> Documento non richiesto (caricamento facoltativo)
                        </div>
                    )}

                    {(status === 'expiring') && (
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '6px' }}>
                            <EventIcon style={{ marginRight: '4px' }} /> Documento in scadenza
                        </div>
                    )}

                    {(status === 'reviewing' && isAdmin) && (
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '6px' }}>
                            <CheckCircleOutlineIcon style={{ marginRight: '4px' }} /> In attesa di approvazione
                        </div>
                    )}

                    {/* TODO: check this conditions */}
                    {(status === 'missing' || status === 'expiring' || true) && (
                        <>
                            {(status === 'missing' || status === 'expiring' || status === 'missing_pre_birthday') && formAsset && (
                                <FormAsset asset={formAsset} />
                            )}

                            <FileUploader initialAssets={initialAssets} documentId={id} status={status} savedAssets={document.assets ?? []} handleAssetChange={handleAssetChange} isAdmin={isAdmin} />
                        </>
                    )}

                    {(status === 'reviewing' && isAdmin && showAdminButtons) && (
                        <div style={{ marginTop: '6px' }}>
                            <Button onClick={() => setIsUnapproveDialogOpen(true)} color={'secondary'} variant={'outlined'} style={{ backgroundColor: '#ffffff' }}>
                                Rifiuta
                            </Button>{' '}
                            <Button onClick={() => setIsApproveDialogOpen(true)} color={'primary'} variant={'contained'}>
                                Approva
                            </Button>
                        </div>
                    )}
                </CardContent>
            </Card>

            <UnapproveDialog documentId={documentId ?? ''} open={isUnapproveDialogOpen} closeDialog={(refresh?: boolean) => {
                if (refresh && refreshRegistrations) {
                    refreshRegistrations();
                    if (closeDialog) closeDialog();
                }
                setIsUnapproveDialogOpen(false);
            }} />

            <ApproveDialog documentId={documentId ?? ''} documentType={documentType} open={isApproveDialogOpen} closeDialog={(refresh?: boolean) => {
                if (refresh && refreshRegistrations) {
                    refreshRegistrations();
                    if (closeDialog) closeDialog();
                }
                setIsApproveDialogOpen(false);
            }} />
        </>
    );
};

export default DocumentCard;
