import { Dispatch, SetStateAction } from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import CustomTextField from '../../../../../../common/components/CustomTextField/CustomTextField';
import GenericObject from '../../../../../../typesAdditional/GenericObject';

interface Props {
    courseId: string;
    selectedLocationId: string;
    setCourseId: Dispatch<SetStateAction<string>>;
    setSelectedLocationId: Dispatch<SetStateAction<string>>;
    setSelectedSessions: Dispatch<SetStateAction<string[]>>;
    isLoading: boolean;
    courses: GenericObject[];
    courseData: any;
    seasons: GenericObject[];
    seasonId: string;
    setSeasonId: Dispatch<SetStateAction<string>>;
}

const CourseSelector = (props: Props) => {
    const { courseId, selectedLocationId, setCourseId, setSelectedLocationId, setSelectedSessions, isLoading, courses, courseData, seasons, seasonId, setSeasonId } = props;

    return (
        <Paper style={{padding: '16px'}}>
            <div style={{maxWidth: '400px'}}>
                <Typography variant='h4' style={{fontSize: '1.6em', textAlign: 'left', margin: '2px 2px 14px'}}>
                    Informazioni corso
                </Typography>

                <CustomTextField
                    select
                    label={'Stagione'}
                    value={seasonId}
                    onChange={(e: any) => {
                        setSeasonId(e.target.value);
                        localStorage.setItem('courses_season_id', e.target.value);
                    }}
                    variant='outlined'
                    required
                    fullWidth
                >
                    {seasons.map((opt: any) => (
                        <MenuItem key={opt.id} value={opt.id} style={{whiteSpace: 'break-spaces'}}>
                            {opt.name}
                        </MenuItem>
                     ))}
                 </CustomTextField>

                <CustomTextField
                    select
                    label='Corso'
                    value={courseId}
                    onChange={(e: any) => {
                        setSelectedLocationId('');
                        setCourseId(e.target.value);
                    }}
                    variant='outlined'
                    disabled={isLoading}
                >
                    {courses.sort((course1: any, course2: any) => {
                        return course1.name.localeCompare(course2.name);
                    }).map((course: any) => (
                        <MenuItem key={course.id} value={course.id}>
                            {course.name}
                        </MenuItem>
                     ))}
                 </CustomTextField>

                 <CustomTextField
                     select
                     label='Paese'
                     value={selectedLocationId}
                     onChange={(e: any) => {
                         setSelectedSessions([]);
                         setSelectedLocationId(e.target.value);
                     }}
                     variant='outlined'
                     fullWidth
                     disabled={isLoading || courseId === ''}
                 >
                     {(courseData?.locations ?? []).sort((location1: any, location2: any) => {
                         return location1.town.name.localeCompare(location2.town.name);
                     }).map((location: any) => (
                         <MenuItem key={location.id} value={location.id}>
                             {location.townName}
                         </MenuItem>
                      ))}
                  </CustomTextField>
            </div>
        </Paper>
    );
};

export default CourseSelector;
