import { useEffect, useState } from 'react';

import { ButtonProps } from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import 'moment/locale/it';

import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import api from '../../../../../../common/utils/api';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    emailGroupId: string;
}

const EmailRecipientsDialog = (props: Props) => {
    const { isOpen, onClose, emailGroupId } = props;

    const [isLoading, setIsLoading] = useState(false);

    const [data, setData] = useState<any>({});

    useEffect(() => {
        if (!emailGroupId) {
            return;
        }

        setData({});
        setIsLoading(true);
        api.request('/admin/emails/' + emailGroupId).then((res: any) => {
            setData(res);
            setIsLoading(false);
        });
    }, [emailGroupId]);

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => onClose()
        },
    ];

    return (
        <>
            <CustomDialogWrapper open={isOpen} onClose={() => onClose()} title={'Destinatari email'} buttons={buttons} isLoading={isLoading} maxWidth='xl' fullWidth >
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Invio effettivo</TableCell>
                                <TableCell>Data visualizzazione</TableCell>
                                <TableCell>Data apertura link</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(data?.emails ?? []).sort((mail1: any, mail2: any) => {
                                return mail1.address.localeCompare(mail2.address);
                            }).map((v: any, idx: number) => {
                                return (
                                    <TableRow style={{backgroundColor: (v.seenAt || v.openedAt) ? '#edf7ed' : (!v.sentAt ? '#fdecea' : '#fff4e5')}} >
                                        <TableCell>{idx + 1}</TableCell>
                                        <TableCell>{v.address}</TableCell>
                                        <TableCell>{v.sentAt ? moment(v.sentAt).format('DD/MM/YYYY HH:mm:ss') : ''}</TableCell>
                                        <TableCell>{(v.seenAt || v.openedAt) ? moment(v.seenAt ? v.seenAt : v.openedAt).format('DD/MM/YYYY HH:mm:ss') : ''}</TableCell>
                                        <TableCell>{v.openedAt ? moment(v.openedAt).format('DD/MM/YYYY HH:mm:ss') : ''}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CustomDialogWrapper>
        </>
    );
};

export default EmailRecipientsDialog;
