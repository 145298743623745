import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';

import { dataLayerPush } from './gtm';
import snackbar from './snackbar';
type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

interface LoginApiResponse {
    status: 'email_magic_link' | 'login_ok';
    error?: 'token_invalid' | 'token_expired';
    userId: string;
    token: string;
}

const api = {
    request: (url: string = '', method: HttpMethod = 'GET', data: any = null, displayError: boolean = true, raw: false | 'text' | 'blob' = false, includeRaw: boolean = false) => {
        let marketingCookie = false;

        try {
            // @ts-ignore
            marketingCookie = Cookiebot?.consent?.marketing;
        } catch {
            console.log('x');
        }

        return new Promise((resolve: (value?: any) => void, reject) => {
            const finalData = raw ? data : (data && snakecaseKeys(data));
            const finalUrl = (['GET', 'DELETE'].includes(method) && finalData) ? (url.replace(/\/$/, '') + '?' + new URLSearchParams(finalData)) : (url.replace(/\/$/, ''));

            fetch(process.env.REACT_APP_API_HOST + finalUrl, {
                method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                    'X-MARKETING-COOKIE': (marketingCookie ? '1' : '0'),
                    // @ts-ignore
                    'X-IKEPILI': (window?.xIkepili ?? '')
                },
                credentials: 'include',
                ...(finalData && ['POST', 'PUT'].includes(method) && { body: JSON.stringify(finalData) })
            }).then(res => {
                const { status, headers } = res;
                const success = (status >= 200 && status <= 299);
                const afterJsonDecode = success ? resolve : reject;

                if (headers.get('X-IKEPILI')) {
                    // @ts-ignore
                    window.xIkepili = headers.get('X-IKEPILI');
                }

                if (status === 204) {
                    resolve();
                }
                else {
                    if (raw === 'text' && success) {
                        res.text().then((output: any) => {
                            resolve(output);
                        }).catch(() => {
                            if (displayError) {
                                snackbar.error('Impossibile completare la richiesta (errore ' + status + ')');
                            }
                        });
                    }
                    else if (raw === 'blob' && success) {
                        res.blob().then((output: any) => {
                            resolve({ content: output, headers });
                        }).catch(() => {
                            if (displayError) {
                                snackbar.error('Impossibile completare la richiesta (errore ' + status + ')');
                            }
                        });
                    }
                    else {
                        res.json().then(json => {
                            if (!success && json.force_logout && url !== '/auth/me' && url !== '/admin/auth/me') {
                                api.logout();

                                // @ts-ignore
                                if (!window.noRedirectToHome) {
                                    window.location.href = process.env.PUBLIC_URL + '/login_dialog';
                                }
                            }

                            if (!success && displayError && json && !json.silent_error) {
                                // if the request is failed and displayError is true, show errors using snackbar
                                if (json.error_desc) {
                                    // generic errors are in the error attribute
                                    snackbar.error(json.error_desc);
                                }
                                else {
                                    // in case of validation errors, every field has its error message, so we cycle and display all of them
                                    Object.keys(json).map((key: string) => {
                                        return snackbar.error(json[key]);
                                    });
                                }
                            }

                            if (includeRaw) {
                                return afterJsonDecode({ ...camelcaseKeys(json, { deep: true }), _raw: json });
                            } else {
                                return afterJsonDecode(camelcaseKeys(json, { deep: true }));
                            }

                        }).catch(() => {
                            if (displayError) {
                                snackbar.error('Impossibile completare la richiesta (errore ' + status + ')');
                            }

                            reject();
                        });
                    }
                }
            }).catch((e) => {
                console.log(e);
                snackbar.error('Impossibile comunicare con il server. Verifica la tua connessione ad internet.', { autoHideDuration: 30000 });
                reject();
            });
        });
    },
    userLogin: (email: string, quickLogin: boolean = false) => {
        return new Promise((resolve: (status: string) => void, reject) => {
            api.request('/auth/login', 'POST', { email: email.trim(), quickLogin, redirectUrl: window.location.pathname + window.location.search.replace('iframe', 'noiframe') }).then((res: LoginApiResponse) => {
                const { status, token, userId } = res;

                if (token) {
                    localStorage.setItem('access_token', token);

                    dataLayerPush({
                        event: 'login_successful',
                        visitorLoggedIn: '1',
                        user_id: userId
                    });
                } else {
                    dataLayerPush({
                        event: 'login_attempt',
                        visitorLoggedIn: '0'
                    });
                }

                resolve(status);
            }).catch((res) => {
                dataLayerPush({
                    event: 'login_unsuccessful',
                    visitorLoggedIn: '0',
                    error_message: res?.errorDesc ?? res?.error_desc ?? ''
                });

                reject(res);
            });
        });
    },
    userLoginWithToken: (loginToken: string) => {
        return new Promise((resolve, reject) => {
            api.request('/auth/token_login', 'POST', { token: loginToken }, false).then((res: LoginApiResponse) => {
                const { status, token, userId } = res;

                if (token) {
                    localStorage.setItem('access_token', token);

                    dataLayerPush({
                        event: 'login_successful',
                        visitorLoggedIn: '1',
                        user_id: userId
                    });
                }

                resolve(status);
            }).catch((res) => {
                dataLayerPush({
                    event: 'login_unsuccessful',
                    visitorLoggedIn: '0',
                    error_message: res?.errorDesc ?? res?.error_desc ?? ''
                });

                reject(res);
            });
        });
    },
    superUserLogin: (email: string, password: string, newPassword?: string) => {
        return new Promise((resolve: (status: string) => void, reject) => {
            api.request('/admin/auth/login', 'POST', { email: email.trim(), password, newPassword, redirectUrl: window.location.pathname + window.location.search.replace('iframe', 'noiframe') }).then((res: LoginApiResponse) => {
                const { status, token } = res;

                if (token) {
                    localStorage.setItem('access_token', token);
                }

                resolve(status);
            }).catch((res) => {
                reject(res);
            });
        });
    },
    logout: () => {
        localStorage.removeItem('access_token');
    },
    isUserLoggedIn: () => {
        return new Promise((resolve) => {
            api.request('/auth/me', 'GET', null, false).then(() => {
                resolve(true);
            }).catch(() => {
                resolve(false);
            });
        });
    },
    isSuperUserLoggedIn: () => {
        return new Promise((resolve) => {
            api.request('/admin/auth/me', 'GET', null, false).then(() => {
                resolve(true);
            }).catch(() => {
                resolve(false);
            });
        });
    }
};

export default api;
