import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useWindowSize } from 'react-use';

import { Button, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import CustomTextField from '../../../../common/components/CustomTextField/CustomTextField';
import useInternalLoader from '../../../../common/hooks/useInternalLoader/useExternalLoader';
import api from '../../../../common/utils/api';
import GenericObject from '../../../../typesAdditional/GenericObject';
import { Title } from './styled';

const RegistrationsPage = () => {
    const setIsLoading = useInternalLoader();

    const { width } = useWindowSize();
    const history = useHistory();

    const [courses, setCourses] = useState<GenericObject[]>([]);
    const [seasons, setSeasons] = useState<GenericObject[]>([]);
    const [seasonId, setSeasonId] = useState('');

    useEffect(() => {
        api.request('/admin/seasons').then((resSeasons: any) => {
            setSeasons(resSeasons);

            const wantedSeasonId = localStorage.getItem('courses_season_id') ?? 'b84ce792-ba8d-45b1-9831-d02e89ceff67';

            if (resSeasons.find((s: any) => s.id === wantedSeasonId)) {
                setSeasonId(wantedSeasonId ?? '');
            } else {
                setSeasonId(resSeasons[0]?.id ?? '');
            }
        }).catch(() => {
            setIsLoading(false);
        });
    }, [setIsLoading]);

    useEffect(() => {
        if (!seasonId) return;

        api.request('/admin/courses', 'GET', { season: seasonId }).then((res: any) => {
            setCourses(res);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [seasonId, setIsLoading]);

    const isMobile = width <= 700;

    return (
        <>
            <Title isMobile={isMobile}>
                <div style={{ flex: '1', flexGrow: 1 }}>
                    Gestione iscrizioni
                </div>
                <div style={{ flex: '1', flexGrow: 1, maxWidth: '200px', marginTop: '4px' }}>
                    <CustomTextField
                        select
                        label={'Stagione iscrizioni'}
                        value={seasonId}
                        onChange={(e: any) => {
                            setSeasonId(e.target.value);
                            localStorage.setItem('courses_season_id', e.target.value);
                        }}
                        variant='outlined'
                        required
                        fullWidth
                    >
                        {seasons.map((opt: any) => (
                            <MenuItem key={opt.id} value={opt.id} style={{ whiteSpace: 'break-spaces' }}>
                                {opt.name}
                            </MenuItem>
                        ))}
                    </CustomTextField>
                </div>
            </Title>

            {courses.sort((c1, c2) => c1.order - c2.order).map(course => {
                return (
                    <div style={{ marginBottom: '32px' }}>
                        <Typography variant='h5' style={{ fontSize: '1.7em', marginBottom: '6px', marginLeft: '4px' }}>
                            {course.name}
                        </Typography>

                        <TableContainer component={Paper} style={{ maxWidth: '600px' }}>
                            <Table size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Paese</TableCell>
                                        <TableCell style={{ width: '180px' }}>Numero iscrizioni</TableCell>
                                        <TableCell style={{ width: '200px' }}>Elenco</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {course.locations.sort((l1: any, l2: any) => l1.town.name.localeCompare(l2.town.name)).map((location: any) => {
                                        return (
                                            <>
                                                <TableRow>
                                                    <TableCell>{location.townName}</TableCell>
                                                    <TableCell>{location.registrationCount}</TableCell>
                                                    <TableCell>
                                                        <Button onClick={() => history.push('/admin/registrations/' + course.id + '/locations/' + location.id)} color={'primary'}>
                                                            Visualizza elenco
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>

                                                {location.registrationCountWaitingList > 0 && (
                                                    <TableRow>
                                                        <TableCell>{location.townName} (lista d'attesa)</TableCell>
                                                        <TableCell>{location.registrationCountWaitingList}</TableCell>
                                                        <TableCell>
                                                            <Button onClick={() => history.push('/admin/registrations/' + course.id + '/locations/' + location.id + '?waiting_list=true')} color={'primary'}>
                                                                Visualizza elenco
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                );
            })}
        </>
    );
};

export default RegistrationsPage;
