import { Dispatch, SetStateAction } from 'react';

import { FinalRoute } from '../types';

interface Params {
    lastPath: string;
    setLastPath: Dispatch<SetStateAction<string>>;
    setIsLoading: (isLoading: boolean) => void;
    setOpenedSection: Dispatch<SetStateAction<string>>;
    parentGuid?: string;
}

const useRouteClick = (params: Params) => {
    const { lastPath, setLastPath, setIsLoading, setOpenedSection, parentGuid } = params;

    return (e: React.UIEvent, route: FinalRoute) => {
        if (route.subRoutes) {
            setOpenedSection(s => s === route.guid ? '' : route.guid);
            e.preventDefault();
            return;
        }

        if (route.path !== lastPath) {
            setLastPath(route.path);
            setIsLoading(true);
            setOpenedSection(parentGuid ?? '');

            setTimeout(() => {
                setIsLoading(false);
            }, 500);
        }

        if (route.workInProgress) {
            e.preventDefault();
            return false;
        }
    };
};

export default useRouteClick;
