import { useEffect, useState, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

import api from '../../../../../../common/utils/api';

const useFirstParentDataValidator = (courseId: string, data: any): any => {
    const { parent } = data;

    const [validationResult, setValidationResult] = useState({ isLoading: false });

    const apiRequestId = useRef('');

    useEffect(() => {
        if (!courseId) return;

        const prefix = window.location.pathname.startsWith('/admin/registrations/new') ? '/admin/courses/' : (
            window.location.pathname.startsWith('/admin') ? '/admin/basic_courses/' : '/courses/'
        );

        setValidationResult(oldResult => {
            return {
                ...oldResult,
                isLoading: true
            };
        })

        const timeout = setTimeout(() => {
            const currentRequestId = uuidv4();
            apiRequestId.current = currentRequestId;

            api.request(prefix + courseId + '/validate_data/tax_code', 'POST', parent, false).then(res => {
                if (currentRequestId !== apiRequestId.current) return;

                setValidationResult(oldResult => {
                    return {
                        ...oldResult,
                        taxCode: res,
                        isLoading: false
                    };
                });
            }).catch(() => {
                if (currentRequestId !== apiRequestId.current) return;

                setValidationResult(oldResult => {
                    return {
                        ...oldResult,
                        taxCode: null,
                        isLoading: false
                    };
                });
            });
        }, 250);

        return () => clearTimeout(timeout);
    }, [courseId, parent, parent?.name, parent?.surname, parent?.tax_code]);

    return validationResult;
};

export default useFirstParentDataValidator;
