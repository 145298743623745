import { useCallback, useEffect, useState } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { downloadBlob } from 'download.js';

import api from '../../../../../../../../../../../common/utils/api';
import { Asset } from '../../../../../types';
import PreviewDialog from '../../PreviewDialog/PreviewDialog';

interface Props {
    asset: Asset;
    handleRemove: () => void;
    isAdmin?: boolean;
}

const FileItem = (props: Props) => {
    const { asset, handleRemove, isAdmin } = props;

    const [isDownloading, setIsDownloading] = useState(false);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [previewUrl, setPreviewUrl] = useState('');

    const downloadAsset = () => {
        setIsDownloading(true);
        api.request((isAdmin ? '/admin' : '') + '/assets/private/' + asset.id, 'GET', undefined, true, 'blob').then(res => {
            downloadBlob(res.headers.get('X-File-Name'), res.content);
            setIsDownloading(false);
        });
    };

    const previewAsset = useCallback((silent: boolean = false) => {
        if (!silent) setIsDownloading(true);

        api.request((isAdmin ? '/admin' : '') + '/assets/private/' + asset.id + '?force_pdf=true', 'GET', undefined, false, 'blob').then(res => {
            if (silent) return;

            const url = URL.createObjectURL(res.content);
            setPreviewUrl(url);
            setIsPreviewOpen(true);
            setIsDownloading(false);
            setTimeout(() => {
                URL.revokeObjectURL(url);
            }, 5000);
        });
    }, [asset, isAdmin]);

    useEffect(() => {
        if (!(asset.status === 'uploaded' || asset.status === 'saved')) return;

        previewAsset(true);
    }, [asset, previewAsset]);

    return (
        <ListItem>
            <ListItemText primary={asset.originalName} />

            {(asset.status === 'uploaded' || asset.status === 'saved') && (
                <>
                    {isDownloading ? (
                        <CircularProgress style={{width: '22px', height: '22px', marginRight: '2px'}} />
                    ) : (
                        <>
                            <IconButton style={{padding: '4px', marginRight: '2px'}} onClick={() => previewAsset()} edge='end'>
                                <VisibilityIcon />
                            </IconButton>
                            <IconButton style={{padding: '4px', marginRight: '-4px'}} onClick={downloadAsset} edge='end'>
                                <GetAppIcon />
                            </IconButton>
                        </>
                    )}

                    {asset.status === 'uploaded' && (
                        <IconButton onClick={handleRemove} style={{padding: '4px', marginRight: '-4px', marginLeft: '10px'}} edge='end'>
                            <DeleteIcon />
                        </IconButton>
                    )}
                </>
            )}

            {asset.status === 'uploading' && (
                <CircularProgress style={{width: '22px', height: '22px'}} />
            )}

            <PreviewDialog open={isPreviewOpen} closeDialog={() => setIsPreviewOpen(false)} url={previewUrl} />

        </ListItem>
    );
};

export default FileItem;
