import { useMemo } from "react";
import GenericObject from "../../../../../../../typesAdditional/GenericObject";

interface Params {
    suggestions: any[];
    savedSessions: any[],
    currentRegistrationSessions: GenericObject[];
}

const usePriceOffset = (params: Params) => {
    const { suggestions, savedSessions, currentRegistrationSessions } = params;

    const groupAPrices = [140, 115, 105];
    const groupBPrices = [250, 200];

    const groupASessions = useMemo(() => {
        if (!suggestions || !suggestions.length) return [];

        return (savedSessions.find(g => g.group === 'A')?.sessions ?? []).concat(
            currentRegistrationSessions.filter(sess => suggestions.find(s => s.id === sess.id).location.course.name.toLowerCase() !== 'yoga').map(s => s.id)
        )
    }, [currentRegistrationSessions, suggestions, savedSessions])

    const groupBSessions = useMemo(() => {
        if (!suggestions || !suggestions.length) return [];

        return (savedSessions.find(g => g.group === 'B')?.sessions ?? []).concat(
            currentRegistrationSessions.filter(sess => suggestions.find(s => s.id === sess.id).location.course.name.toLowerCase() === 'yoga').map(s => s.id)
        )
    }, [currentRegistrationSessions, suggestions, savedSessions])

    console.log('A', groupASessions);
    console.log('B', groupBSessions);

    const getSessionPriceWithOffset = (sessionId: string) => {
        if (!suggestions || !suggestions.length) return 0;

        const isAdult = suggestions.find(s => s.id === sessionId).location.course.course_category_id === 2;
        const isYoga = suggestions.find(s => s.id === sessionId).location.course.name.toLowerCase() === 'yoga';

        if (!isAdult) return suggestions.find(s => s.id === sessionId).prices.course.actual.price;

        if (!isYoga) {
            const index = groupASessions.findIndex((s: any) => s === sessionId);
            const realIndex = (index >= 0) ? index : groupASessions.length;

            return groupAPrices[Math.min(groupAPrices.length - 1, realIndex)];
        } else {
            const index = groupBSessions.findIndex((s: any) => s === sessionId);
            const realIndex = (index >= 0) ? index : groupBSessions.length;

            return groupBPrices[Math.min(groupBPrices.length - 1, realIndex)];
        }
    }

    return { getSessionPriceWithOffset }
}

export default usePriceOffset;