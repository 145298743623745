import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import { ReduxState } from '../../../redux/types';
import { SuperUserRoute } from '../../../typesAdditional/Route';
import routes from '../routes';
import { Container, ExternalContainer } from './styled';

interface Props {
    appbarVisible: boolean;
}

const Router = (props: Props) => {
    const { appbarVisible } = props;

    const userInfo = useSelector((state: ReduxState) => state.user);
    const isUserLoggedIn = userInfo.id.length > 0;

    const finalRoutes = useMemo(() => {
        const fallbackRoute: SuperUserRoute = {
            name: 'Fallback redirect',
            path: '*',
            showSidebar: false,
            canAuth: true,
            component: <Redirect to={'/admin'} />,
        };

        const routesWithSubroutes = routes.reduce((prev: SuperUserRoute[], current: SuperUserRoute) => {
            return [...prev, current, ...(current.subRoutes ?? [])];
        }, []);

        return  [...routesWithSubroutes, fallbackRoute].map(route => {
            if (!isUserLoggedIn && route.requireAuth) {
                return (
                    <Route
                        path={'/admin' + route.path}
                        exact={route.exactPath}
                        component={() => <Redirect to={'/admin/login'} />}
                        key={route.path}
                    />
                );
            }

            if (isUserLoggedIn && !route.canAuth) return null;
            if (route.needAdmin && !userInfo.administrator) return null;

            return (
                <Route
                    path={'/admin' + route.path}
                    exact={route.exactPath}
                    component={() => {
                        // TODO: report to Sentry if component is undefined and there aren't subroutes
                        return route.component ?? null;
                    }}
                    key={route.path}
                />
            );
        }).filter((r: any) => r !== null);
    }, [isUserLoggedIn, userInfo]);

    return (
        <ExternalContainer appbarVisible={appbarVisible}>
            <Container>
                <Switch>
                    {finalRoutes}
                </Switch>
            </Container>
        </ExternalContainer>
    );
};

export default Router;
