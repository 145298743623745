import Button, { ButtonProps } from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent, { DialogContentProps } from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components/macro';

import CustomDialog, { Props as CustomDialogProps } from '../CustomDialog/CustomDialog';

interface Props extends CustomDialogProps {
    title?: string;
    buttons?: ButtonProps[];
    contentProps?: DialogContentProps;
    isLoading?: boolean;
}

const CustomDialogWrapper = (props: Props) => {
    const { title, buttons, contentProps, isLoading, children, onClose,  ...otherProps } = props;

    return (
        <CustomDialog {...{...otherProps, onClose}} >
            <DialogTitle>
                {title}
                {onClose && (
                    // @ts-ignore
                    <StyledIconButton onClick={onClose}>
                        <CloseIcon />
                    </StyledIconButton>
                )}
            </DialogTitle>
            <DialogContent dividers {...contentProps} >
                {children}
            </DialogContent>
            <DialogActions>
                {isLoading && (
                    <div style={{position: 'absolute', left: '14px', bottom: '8px'}}>
                        <CircularProgress style={{width:'28px', height: '28px'}} />
                    </div>
                )}
                {buttons?.map(buttonProps => {
                    return <Button {...{...buttonProps, ...(isLoading && { disabled: true })}} />;
                })}
            </DialogActions>
        </CustomDialog>
    );
};

const StyledIconButton = styled(IconButton)`
    position: absolute;
    right: 0;
    top: 0;
    padding: 20px;
`;

export default CustomDialogWrapper;
