import { useCallback, useMemo } from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';

import { useMessageDialog } from '../../../../../../../../../common/hooks/useMessageDialog/useMessageDialog';
import { SingleSessionSelectorProps } from '../../../types';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

interface Props extends SingleSessionSelectorProps {
    isDifferentCourse: boolean;
    showTownName?: boolean;
    applyPriceOffset?: boolean;
    getSessionPriceWithOffset?: (sessionId: string) => number;
    pricesNotAvailable?: boolean;
    singleSessionEvent?: boolean;
}

const SessionRow = (props: Props) => {
    const { sessionType, session, hasFoodService, isSelected, isFoodSelected, handleSelect, handleFoodSelect, isDifferentCourse, showTownName, applyPriceOffset, getSessionPriceWithOffset, pricesNotAvailable, singleSessionEvent } = props;

    const [showMessageDialog] = useMessageDialog();

    const handleBlockedSession = useCallback((reason: string) => {
        if (reason === 'edit_too_late') {
            showMessageDialog({
                title: 'Impossibile modificare l\'iscrizione',
                message: <>
                    <p style={{ marginTop: '0px' }}>Non è più possibile modificare la sua iscrizione.</p>
                    <p>Le modifiche alle iscrizioni possono essere effettuate entro 30 giorni dall'inizio del progetto. Per ulteriori necessità scriva a <Link href='mailto:info@epuntos.it' target='_blank' rel='noreferrer'>info@epuntos.it</Link>.</p>
                    <p style={{ marginBottom: '0px' }}>Grazie</p>
                </>
            });
        } else if (reason === 'too_late') {
            showMessageDialog({
                title: 'Impossibile modificare l\'iscrizione',
                message: <>
                    <p style={{ marginTop: '0px' }}>Non è più possibile modificare la sua iscrizione.</p>
                    <p style={{ marginBottom: '0px' }}>Le iscrizioni possono essere effettuate entro il giorno prima dall'inizio del progetto. Per ulteriori necessità scriva a <Link href='mailto:info@epuntos.it' target='_blank' rel='noreferrer'>info@epuntos.it</Link>.</p>
                </>
            });
        } else if (reason === 'disabled') {
            showMessageDialog({
                title: 'Impossibile modificare l\'iscrizione',
                message: <>
                    <p style={{ marginTop: '0px' }}>Non è più possibile modificare la sua iscrizione.</p>
                    <p style={{ marginBottom: '0px' }}>Per ulteriori necessità scriva a <Link href='mailto:info@epuntos.it' target='_blank' rel='noreferrer'>info@epuntos.it</Link>.</p>
                </>
            });
        } else if (reason === 'welfare') {
            showMessageDialog({
                title: 'Impossibile modificare l\'iscrizione',
                message: <>
                    <p style={{ marginTop: '0px' }}>Non puoi modificare l'iscrizione in questo momento, devi prima completare l'iscrizione welfare.</p>
                    <p style={{ marginBottom: '0px' }}>Per ulteriori necessità scrivi a <Link href='mailto:info@epuntos.it' target='_blank' rel='noreferrer'>info@epuntos.it</Link>.</p>
                </>
            });
        } else {
            showMessageDialog({
                title: 'Impossibile modificare l\'iscrizione',
                message: <>
                    <p style={{ marginTop: '0px' }}>Non è più possibile modificare l'iscrizione.</p>
                    <p style={{ marginBottom: '0px' }}>Le iscrizioni possono essere modificate entro il termine del progetto.</p>
                </>
            });
        }
    }, [showMessageDialog]);

    const date = useMemo(() => {
        if (singleSessionEvent) {
            return 'Adesione alla gita - 24/25/26 Maggio 2023'
        } else if (sessionType === 'daily_week_events') {
            return moment(session.startTime).format('D MMMM YYYY') + ' - ' + moment(session.endTime).format('D MMMM YYYY');
        } else if (sessionType === 'weekly_events') {
            return (
                <>
                    <strong>{moment(session.startTime).format('dddd')}</strong>, dalle ore <strong>{moment(session.startTime).format('H:mm')}</strong> alle ore <strong>{moment(session.endTime).format('H:mm')}</strong>
                </>
            );
        } else {
            return moment(session.startTime).format('dddd D MMMM YYYY');
        }
    }, [singleSessionEvent, session, sessionType]);

    return (
        <TableRow key={session.id}>
            <TableCell align='left'>{isDifferentCourse && (
                <><b>{session.location.course.name}</b><br /></>
            )}{date}{showTownName && (
                <><br /><b>{session.location.townName}</b></>
            )}</TableCell>
            <TableCell align='center' colSpan={(hasFoodService && session.prices.course.actual.foodIncludedInPrice && session.hasFoodService) ? 2 : 1}>
                {!pricesNotAvailable && (
                    <p style={{ margin: 0 }}>€{((applyPriceOffset && getSessionPriceWithOffset) ? getSessionPriceWithOffset(session.id) : session.prices.course.actual.price).toFixed(2)}</p>
                )}

                <div style={{ display: 'inline-block' }} onClick={() => session.disabled && handleBlockedSession(session.disabledReason)}>
                    {!singleSessionEvent ? (
                        <Checkbox
                            color='primary'
                            style={{ padding: '4px' }}
                            checked={isSelected(session.id) || ((session.status === 'registered' || session.status === 'queue') && session.disabled)}
                            onChange={(e) => {
                                handleSelect(session.id, e.target.checked)

                                if (e.target.checked && session.prices.course.actual.foodIncludedInPrice) {
                                    handleFoodSelect(session.id, e.target.checked)
                                }
                            }}
                            disabled={session.disabled}

                        />
                    ) : (
                        <FormControl component='fieldset'>
                            <RadioGroup aria-label='gender' name='question' value={(isSelected(session.id) || session.status === 'registered' || session.status === 'queue') ? 'yes' : 'no'} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                const value = (event.target as HTMLInputElement).value;
                                handleSelect(session.id, value === 'yes')
                            }}>
                                {/*<FormControlLabel value='no' control={<Radio color='primary' />} label='no' disabled={session.disabled} />*/}
                                <FormControlLabel value='yes' control={<Radio color='primary' />} label='sì' disabled={session.disabled} />
                            </RadioGroup>
                        </FormControl>
                    )}
                </div>
            </TableCell>

            {hasFoodService && !session.prices.course.actual.foodIncludedInPrice && (
                <TableCell align='center'>
                    <p style={{ margin: 0 }}>
                        {session.hasFoodService ? (
                            <>
                                €{session.prices.foodService.actual.price.toFixed(2)}
                            </>
                        ) : (
                            <>
                                ---
                            </>
                        )}
                    </p>
                    <div style={{ display: 'inline-block' }} onClick={() => session.foodDisabled && handleBlockedSession(session.disabledReason)}>
                        <Checkbox
                            color='primary'
                            style={{ padding: '4px' }}
                            checked={isFoodSelected(session.id) || ((session.status === 'registered' || session.status === 'queue') && session.userWantsThisSessionFoodService)}
                            onChange={(e) => handleFoodSelect(session.id, e.target.checked)}
                            disabled={session.foodDisabled}
                        />
                    </div>
                </TableCell>
            )}

        </TableRow>
    );
};

export default SessionRow;
